import axios from "axios";
import config from "./config";

class API {

  renditionURL(imageId, type, width, height) {
    if (!imageId) return;
    return `${config.SERVER_URL}/v1/rendition/${type}/${imageId}?width=${width}&height=${height}`;
  }

  _getOptions() {
    const token = this.getAuthToken();
    return {
      headers: {
        'Authorization': token ? `Bearer ${token}` : ""
      }
    }
  }

  getAuthToken() {
    return localStorage.getItem('OY_AUTH_TOKEN') || "";
  }

  setAuthToken(token) {
    if (!token)
      localStorage.removeItem('OY_AUTH_TOKEN');
    else
      localStorage.setItem('OY_AUTH_TOKEN', token);
  }

  async login(user, password) {
    console.log(`Logging in with user ${user}`);
    const result = await axios.post(`${config.SERVER_URL}/login`, { user:user, password:password });
    console.log(result.data);
    return result.data;
  }

  async logout() {
    const token = this.getAuthToken();
    if (!token) return;
    console.log(`Logging out`);
    try {
      const result = await axios.post(`${config.SERVER_URL}/logout`, {}, this._getOptions());
      console.log(result.data);
      return result.data;
    } finally {
      this.setAuthToken();
    }
  }

  async key(id) {
    console.log(`Fetching key ${id}`);
    try {
      const result = await axios.get(`${config.SERVER_URL}/key/${id}`, this._getOptions());
      console.log(result.data);
      return result.data;
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        return null;
      }
      throw ex;
    }
  }

  async operator() {
    if (!this.getAuthToken()) return;
    console.log(`Fetching current operator`);
    const result = await axios.get(`${config.SERVER_URL}/operator`, this._getOptions());
    console.log(result.data);
    return result.data;
  }

  async version() {
    if (!this.getAuthToken()) return { };
    console.log(`Fetching current version`);
    const result = await axios.get(`${config.SERVER_URL}/version`, this._getOptions());
    console.log(result.data);
    return result.data;
  }

  async keyList(from, max, keyNum) {
    console.log(`Fetching key list`);
    let url = `${config.SERVER_URL}/keys/${from}?max=${max}`;
    if (keyNum !== null && keyNum !== undefined)
      url = url + `&keyNum=${keyNum}`;
    const result = await axios.get(url, this._getOptions());
    console.log(result.data);
    return result.data;
  }

  async events(from, max, dayFilter, keyFilter, imageFilter) {
    console.log(`Fetching events`);
    let url = `${config.SERVER_URL}/events/${from}?max=${max}`;
    if (dayFilter) 
      url = url + `&dayfilter=${dayFilter}`;
      if (keyFilter) 
      url = url + `&keyfilter=${keyFilter}`;
    if (imageFilter) 
      url = url + `&imagefilter=1`;
    const result = await axios.get(url, this._getOptions());
    console.log(result.data);
    return result.data;
  }

  async eventStats() {
    console.log(`Fetching event statistics`);
    const result = await axios.get(`${config.SERVER_URL}/events/stats`, this._getOptions());
    console.log(result.data);
    return result.data;
  }

  async top() {
    console.log(`Fetching top statistics`);
    const result = await axios.get(`${config.SERVER_URL}/keys/active`, this._getOptions());
    console.log(result.data);
    return result.data;
  }

  async travelData() {
    console.log(`Fetching travel data`);
    const result = await axios.get(`${config.SERVER_URL}/v1/keys/travel`, this._getOptions());
    console.log(result.data);
    return result.data;
  }

  async updateProfile(profile) {
    console.log(`Updating profile to ${profile}`);
    const result = await axios.post(`${config.SERVER_URL}/profile`, profile, this._getOptions());
    console.log(result.data);
    return result.data;
  }

  async operatorList() {
    console.log(`Fetching operator list`);
    let url = `${config.SERVER_URL}/operators`;
    const result = await axios.get(url, this._getOptions());
    console.log(result.data);
    return result.data;
  }

  async userList(from, max) {
    console.log(`Fetching user list`);
    let url = `${config.SERVER_URL}/users/${from}?max=${max}`;
    const result = await axios.get(url, this._getOptions());
    console.log(result.data);
    return result.data;
  }  

  async deleteEvent(eventId) {
    console.log(`Deleting event ${eventId}`);
    const result = await axios.delete(`${config.SERVER_URL}/event/${eventId}`, {}, this._getOptions());
    console.log(result.data);
    return result.data;
  }

  async generateMissing(max) {
    console.log(`Generating missing thumbnails (max=${max})`);
    const result = await axios.post(`${config.SERVER_URL}/thumbnails/missing?max=${max}`, {}, this._getOptions());
    console.log(result.data);
    return result.data;
  }

  async getJob(jobId) {
    console.log(`Getting job progress`);
    let url = `${config.SERVER_URL}/job/${jobId}`;
    const result = await axios.get(url, this._getOptions());
    console.log(result.data);
    return result.data;
  }

  async getJobs(max, type) {
    console.log(`Getting jobs progress`);
    let url = `${config.SERVER_URL}/jobs/0?max=${max}`;
    if (type) url = url + `&type=${type}`;
    const result = await axios.get(url, this._getOptions());
    console.log(result.data);
    return result.data;
  }

  async pushToAll(title, body) {
    console.log(`Pushing message to everyone`);
    const result = await axios.post(`${config.SERVER_URL}/push`, { title: title, body: body }, this._getOptions());
    console.log(result.data);
    return result.data;
  }

  async pushToKey(keyIdOrNum, title, body) {
    console.log(`Pushing message to key ${keyIdOrNum}`);
    const result = await axios.post(`${config.SERVER_URL}/push`, { keyId: keyIdOrNum, title: title, body: body }, this._getOptions());
    console.log(result.data);
    return result.data;
  }

  async pushToToken(deviceToken, title, body) {
    console.log(`Pushing message to device token ${deviceToken}`);
    const result = await axios.post(`${config.SERVER_URL}/push`, { deviceToken: deviceToken, title: title, body: body }, this._getOptions());
    console.log(result.data);
    return result.data;
  }
}

const api = new API();
export default api;

