/*
 * (C) Copyright 2021 Open Your [...] (http://openyour.art/) and others.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Contributors:
 *     Alexandre Morin
 */
import React from 'react';
import {createUseStyles} from 'react-jss'

/**
 * A OYButtonBar is very similar to a button group, but is used to lay out 
 * multiple button groups adding padding between each button group.
 */
const useStyles = createUseStyles({
  root: {
    display: "flex",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  horizontal: {
    flexDirection: "row",
    borderBottom: "1px solid #eee",
  },
  vertical: {
    flexDirection: "column",
  },
  childhorizontal: {
    marginRight: "24px",
  },
  childvertical: {
    marginBottom: "24px",
  }
});

const OYButtonBar = (props) => {
  const classes = useStyles();
  const layout = props.layout || "horizontal";
  let children = props.children;
  if (children && !children.length) children = [ children ];
  return (
    <div className={`${classes.root} ${classes[layout]}`}>
      { children.map((c, i) => {
        return (
          <div key={i} className={`${classes["child" + layout]}`}>
            {c}
          </div>);
      }) }
    </div>);
};

export default OYButtonBar;


