import React, { useEffect, useState } from 'react';
import {createUseStyles} from 'react-jss'
import Globe from 'react-globe.gl';
import * as THREE from "three";
import api from './API';
import OYSwitch from './OYSwitch';
import OYTextInput from './OYTextInput';


const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "24px",
    height: "100%",
    backgroundColor: "#F9F9F9"
  },
  loading: {
    marginLeft: "8px",
  },
  map: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  globe: {
    display: "block",
    height: "100%",
  },
  filters: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FFF",
    borderTop: "1px solid #eee",
    borderBottom: "1px solid #ccc",
    //marginTop: "8px",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "18px",
    paddingRight: "8px",
  },
  filter: {
    marginRight: "16px",
  },
  filterCaption: {
    fontSize: "10px"
  }
});

const keyColors = [ "#00B38F", "#FBEB26", "#6FBDFC", "#df8f8f" ];
const arcColors = [ "#00B38F", "#333333", "#6FBDFC", "#df8f8f" ];


function distanceKM(lat1, lon1, lat2, lon2) {
  if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
  }
  else {
      var radlat1 = Math.PI * lat1/180;
      var radlat2 = Math.PI * lat2/180;
      var theta = lon1-lon2;
      var radtheta = Math.PI * theta/180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
          dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344;
      return dist;
  }
}


//let hasResizeListener = false;

const KeyMap = (props) => {
  const classes = useStyles();
/*
  if (!hasResizeListener) {
    hasResizeListener = true;
    window.addEventListener('resize', (event) => {
      console.log(`${event.target.innerWidth} x ${event.target.innerHeight}`)
      //world.width([event.target.innerWidth])
      //world.height([event.target.innerHeight])
    });
  }
*/
  const [polygonsData, setPolygonsData] = useState();
  useEffect(() => {
    fetch('/ne_110m_admin_0_countries.geojson').then(res => res.json())
      .then(countries => {
        setPolygonsData(countries.features.map((f, i) => ({ ...f, color:keyColors[i % keyColors.length] + "44" })));
      });
  }, []);

  const [travels, setTravels] = useState();
  const [arcsData, setArcsData] = useState();
  //const [ringsData, setRingsData] = useState();
  //const [labelsData, setLabelsData] = useState();
  const [pointsData, setPointsData] = useState();

  // Filters
  const [showTestKeys, setShowTestKeys] = useState(false);
  const [showOneKeyId, setShowOneKeyId] = useState("");
  const onToggleTestKeys = (toggle) => {
    setShowTestKeys(toggle);
  }
  const onValidateKeyId = (keyId) => {
    setShowOneKeyId(+keyId);
  }

  useEffect(() => {
    api.travelData().then(data => {
      const travels = data.travels || [];
      setTravels(travels);
    })
  }, []);

  const getRelevantPositions = (positions) => {
    const relevantPositions = [];
    let currentOwner;
    let currentLat = 0;
    let currentLng = 0;
    for (let i=0; i<positions.length; i++) {
      const event = positions[i];
      if (!event.latitude || !event.longitude)
        continue;
      if (!currentOwner) {
        currentOwner = event.nickName;
        currentLat = event.latitude;
        currentLng = event.longitude;
        relevantPositions.push(event);
        continue;
      }
      if (event.eventType === "SCAN") {
        currentOwner = event.nickName;
        currentLat = event.latitude;
        currentLng = event.longitude;
        relevantPositions.push(event);
        continue;
      }
      if (event.nickName === currentOwner) {
        if (currentLat && currentLng) {
          // Ignore if within 0.5 km
          const distance = distanceKM(currentLat, currentLng, event.latitude, event.longitude);
          if (distance < 0.5)
            continue;
        }
        currentLat = event.latitude;
        currentLng = event.longitude;
        relevantPositions.push(event);
        continue;
      }
    }
    return relevantPositions;
  }

  useEffect(() => {
    if (travels) {
      const arcsData = [];
      //const ringsData = [];
      //const labelsData = [];
      const pointsData = [];

      for(var i=0; i<travels.length; i++) {
          const t = travels[i];
          const isTestKey = t.keyNum < 1000;
          const testColor = "#DDD";
          if (isTestKey && !showTestKeys) continue;
          if (showOneKeyId && t.keyNum !== showOneKeyId) continue;

          const positions = getRelevantPositions(t.positions)
          if (positions.length < 2) continue;

          var users = {};
          for(var j=0; j<positions.length - 1; j++) {
              const p1 = positions[j];
              const p2 = positions[j+1];
              arcsData.push({
                startLat: p1.latitude,
                startLng: p1.longitude,
                endLat: p2.latitude,
                endLng: p2.longitude,
                color: isTestKey ? testColor : arcColors[i % arcColors.length],
                isTestKey: isTestKey
              });
              users[p1.nickName] = true;
              users[p2.nickName] = true;
          }
          const p = positions[positions.length - 1];
          const usersCount = Object.keys(users).length;
          
          /*ringsData.push({
              lat: p.latitude,
              lng: p.longitude,
              maxR: 1, 
              propagationSpeed: (Math.random() - 0.5) * 0.8 + 1,
              repeatPeriod: Math.random() * 2000 + 500
          });*/

          pointsData.push({
              lat: p.latitude,
              lng: p.longitude,
              size: 0.04 * usersCount,
              color: isTestKey ? testColor : keyColors[i % keyColors.length],
              name: t.nickName,
              keyNum: t.keyNum
          });
/*
          labelsData.push({
              lat: p.latitude,
              lng: p.longitude,
              text: t.nickName,
              hops: positions.length
        })
          */
      }
      setArcsData(arcsData);
      //setRingsData(ringsData);
      //setLabelsData(labelsData);
      setPointsData(pointsData);
    }
  }, [travels, showTestKeys, showOneKeyId]);


  //const ringColorInterpolator = t => `rgba(255,100,50,${Math.sqrt(1-t)})`;
  //console.log(labelsData);

  //const colorScale = d3.scaleSequentialSqrt(d3.interpolateYlOrRd).domain([0, 100]);
  //console.log(colorScale(0))
  //console.log(colorScale(0.5))
  //console.log(colorScale(2))
  //console.log(colorScale(100))
/*
  if (!travels) {
    return (<div className={classes.root}>
      <div className={classes.loading}>Loading key travel data...</div>
    </div>);
  }
*/
  return (
    <div className={classes.root}>

      <div className={classes.filters}>
      <div className={classes.filter}>
          <div className={classes.filterCaption}>Show test keys</div>
          <OYSwitch defaultValue={showTestKeys} onClick={onToggleTestKeys} captionTrue="Yes" captionFalse="No"></OYSwitch>          
        </div>
        <div className={classes.filter}>
          <div className={classes.filterCaption}>Focus on key#</div>
          <OYTextInput defaultValue={showOneKeyId} onValidate={onValidateKeyId}></OYTextInput>
        </div>
      </div>

      { !travels ? <div className={classes.root}>
          <div className={classes.loading}>Loading key travel data...</div>
        </div>
      : undefined }

      <div className={classes.map}>
        <Globe className={classes.globe} showGlobe={true} showAtmosphere={false} 
               //globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg" 
               //globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg" 
               //globeImageUrl="//unpkg.com/three-globe/example/img/earth-water.png"
               //globeImageUrl="/earth.jpg"
               //globeImageUrl="/earth2.jpg"
               globeMaterial={ new THREE.MeshBasicMaterial({color: 0xFFFFFF}) }
               //backgroundImageUrl='//unpkg.com/three-globe/example/img/night-sky.png'
               backgroundColor={ "#F9F9F9" }
               //globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
               lineHoverPrecision={ 0 }


               polygonsData={polygonsData}
               /*polygonCapMaterial={ new THREE.MeshLambertMaterial({ color: '#b3fff0', side: THREE.DoubleSide }) }
               polygonSideColor={ () => 'rgba(0,0,0,0)' }
               //polygonStrokeColor={ "yellow" }
               polygonAltitude={ 0 }*/
               polygonAltitude={ 0.02 }
               polygonCapColor={ "color" }
               //polygonSideColor={ () => 'rgba(0, 100, 0, 0.15)' }
               polygonSideColor={ () => 'rgba(0,0,0,0)' }
               polygonStrokeColor={ () => '#666' }

               arcsData={ arcsData }
               arcColor={ 'color' }
               //arcColor={ () => "#333" }
               arcStroke={ (d) => d.isTestKey ? undefined : 0.4 }
               arcDashLength={ 0.01 }
               arcDashGap={ 0.01 }
               arcDashAnimateTime={ 2500 }
               //arcLabel={ () => "Hello" }
/*
               ringsData={ ringsData }
               ringColor={ () => "#FF0000" }
               ringMaxRadius={ 'maxR' }
               ringPropagationSpeed={ 'propagationSpeed' }
               ringRepeatPeriod={ 'repeatPeriod' }
               ringAltitude={ 0.025 }
*/
/*
               labelsData={ labelsData }
               //labelText={ "nickName" }
               labelSize={ 0.4 }
               labelDotRadius={ (d) => 0.01 * d.hops }
               labelColor={ () => '#333' }
               labelResolution={ 2 }
               labelAltitude={ 0.03 }
*/
               pointsData = { pointsData }
               pointAltitude = { 'size' }
               pointColor = { 'color' }
               />
      </div>
    </div>
  );
};

export default KeyMap;


