import React from 'react';
import {createUseStyles} from 'react-jss'

const useStyles = createUseStyles({
  root: {
    marginTop: "24px",
    marginLeft: "8px"
  },

});

const OYLoading = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      Loading...
    </div>);
};

export default OYLoading;


