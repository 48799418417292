import React, { useContext, useState } from 'react';
import {createUseStyles} from 'react-jss'
import api from './API';
import { FlashContext } from './FlashProvider';
import OYButton from './OYButton';
import OYSwitch from './OYSwitch';
import OYTextInput from './OYTextInput';

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  pageTitle: {
    marginTop: "14px",
    marginBottom: "4px",
    marginLeft: "8px",
    fontSize: "30px",
    textTransform: "uppercase",
    color: "#00B38F",
  },

  form: {
    display: "flex",
    flexDirection: "column",
    padding: "22px",
    paddingLeft: 0,
  },
  control: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "26px",
    marginLeft: "40px"
  },
  label: {
    fontSize: "12px",
  },
  help: {
    fontSize: "10px",
  },
  warn: {
    fontSize: "12px",
    color: "rgb(179, 0, 143)",
  },
});

const Push = (props) => {
  const classes = useStyles();
  const { onError, onFlash } = useContext(FlashContext);
  
  const [ option, setOption ] = useState("token");

  const [ keyIdOrNum, setKeyIdOrNum ] = useState();
  const [ deviceToken, setDeviceToken ] = useState();
  const [ title, setTitle ] = useState();
  const [ body, setBody ] = useState();

  const onClick = () => {
    if (option === "all") {
      api.pushToAll(title, body).then(() => {
        onFlash(`Push notification sent to everyone`);
      }).catch((err) => {
        console.error(err);
        onError(`Failed to send push notification`);
      });
    }
    if (option === "token") {
      api.pushToToken(deviceToken, title, body).then(() => {
        onFlash(`Push notification sent to device token ${deviceToken}`);
      }).catch((err) => {
        console.error(err);
        onError(`Failed to send push notification`);
      });
    }
    if (option === "key") {
      api.pushToKey(keyIdOrNum, title, body).then(() => {
        onFlash(`Push notification sent to key ${keyIdOrNum}`);
      }).catch((err) => {
        console.error(err);
        onError(`Failed to send push notification`);
      });
    }
  }

  const handleOptionChange = (option) => {
    setOption(option);
  }

  const handleKeyIdOrNumChange = (text) => {
    setKeyIdOrNum(text);
  }

  const handleDeviceTokenChange = (text) => {
    setDeviceToken(text);
  }

  const handleTitleChange = (text) => {
    setTitle(text);
  }

  const handleBodyChange = (text) => {
    setBody(text);
  }

  const disabled = (option === "token" && !deviceToken) ||
                   (option === "key" && !keyIdOrNum);

  return (<div className={classes.root}>

    <div className={classes.pageTitle}>Send Push Notifications</div>

    <div className={classes.form}>

      <div className={classes.control}>
        <div className={classes.label}>Who do you want to push the message to ?</div>
        <div className={classes.switch}>
          <OYSwitch size="M" captions={[ "Token", "Key", "All" ]} values={[ "token", "key", "all" ]} defaultValue={option} onClick={handleOptionChange}/>
        </div>
      </div>

      { option === "key" ?
        <div className={classes.control}>
          <div className={classes.label}>Key id or key #</div>
          <OYTextInput onChange={handleKeyIdOrNumChange}></OYTextInput>
          <div className={classes.help}>Use this option to send to all users of a particular key</div>
        </div>
      : undefined }

      { option === "token" ?
        <div className={classes.control}>
          <div className={classes.label}>Device token</div>
          <OYTextInput onChange={handleDeviceTokenChange}></OYTextInput>
          <div className={classes.help}>Use this option to send to a particular device token</div>
        </div>
      : undefined }

      { option === "all" ?
        <div className={classes.control}>
          <div className={classes.warn}>
            You are about to push a notification to everyone
          </div>
        </div>
      : undefined }

      <div className={classes.control}>
        <div className={classes.label}>Message title</div>
        <OYTextInput onChange={handleTitleChange}></OYTextInput>
      </div>

      <div className={classes.control}>
        <div className={classes.label}>Message body (optional)</div>
        <OYTextInput onChange={handleBodyChange} rows={4}></OYTextInput>
      </div>

      <div className={classes.control}>
        <OYButton caption="Send Push" disabled={disabled} onClick={onClick}></OYButton>
      </div>
    </div>

  </div>);
};

export default Push;

