import React from 'react';
import {createUseStyles} from 'react-jss'
import { MapContainer, TileLayer } from 'react-leaflet';
import EventMarker from './EventMarker';

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  map: {
    width: "100%",
    height: "400px"
  },
  popup: {
    display: "flex",
    flexDirection: "column",
  },
  popupTitle: {
    marginBottom: "4px"
  },
  popupTimestamp: {
    color: "#AAA"
  }
});

const KeyLocationMap = ({ oyKey }) => {
  const classes = useStyles();

  const markers = [];
  let minPosition, maxPosition;
  if (oyKey && oyKey.events && oyKey.events.length > 0) {
    for (let i=0; i<oyKey.events.length; i++) {
      const event = oyKey.events[i];
      if (!event.latitude || !event.longitude) continue;
      if (event.eventType !== "SCAN") continue;
      
      if (!minPosition) minPosition = [ event.latitude, event.longitude ];
      if (event.latitude < minPosition[0]) minPosition[0] = event.latitude;
      if (event.longitude < minPosition[1]) minPosition[1] = event.longitude;

      if (!maxPosition) maxPosition = [ event.latitude, event.longitude ];
      if (event.latitude > maxPosition[0]) maxPosition[0] = event.latitude;
      if (event.longitude > maxPosition[1]) maxPosition[1] = event.longitude;

      markers.push(<EventMarker key={i} event={event}/>);
    }
  }

  if (!minPosition || !maxPosition)
    return null

  const position = [ (minPosition[0] + maxPosition[0])/2, (minPosition[1] + maxPosition[1])/2 ];

  const outerBounds = [
    minPosition,
    maxPosition
  ]

  const tiles = 
  <TileLayer
  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />

  if (markers.length === 1) {
    return (
      <div className={classes.root}>
          <MapContainer className={classes.map} center={position} zoom={13} scrollWheelZoom={true}>
            { tiles }
            { markers }
          </MapContainer>
      </div>);
  }
  else {
    return (
      <div className={classes.root}>
          <MapContainer bounds={outerBounds} scrollWheelZoom={true}>
            { tiles }
            { markers }
          </MapContainer>
      </div>);
  }
};

export default KeyLocationMap;


