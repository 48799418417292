import React, { useContext, useEffect, useState } from 'react';
import {createUseStyles} from 'react-jss'
import OYTable from './OYTable';
import OYTableHeader from './OYTableHeader';
import OYTableHeaderCell from './OYTableHeaderCell';
import OYTableRow from './OYTableRow';
import OYTableTextCell from './OYTableTextCell';
import OYTableImageCell from './OYTableImageCell';
import api from './API';
import OYTableCell from './OYTableCell';
import { Link } from 'react-router-dom';
import OYPagination from './OYPagination';
import { useParams } from "react-router-dom";
import OYTableBody from './OYTableBody';
import { FlashContext } from './FlashProvider';

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  pageTitle: {
    marginTop: "14px",
    marginBottom: "4px",
    marginLeft: "8px",
    fontSize: "30px",
    textTransform: "uppercase",
    color: "#00B38F",
  },
  table: {
    margin: "8px",
  },
  action: {
  },
  actionImage: {
    width: "24px",
    height: "24px",
    color: "#00B38F",
    fill: "#00B38F",
    opacity: .5,
    cursor: "pointer",
    "&:hover": {
      opacity: 1
    }  
  },
  actionList: {
  },
  range: {
    fontSize: 12,
    marginRight: "12px"
  }
});


const Action = ({ image, caption, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.action}>
      <img className={classes.actionImage} src={image} alt={caption} onClick={onClick}></img>
    </div>
  );
}

const ActionList = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.actionList}>
      { props.children }
    </div>
  );
}

const KeyList = (props) => {
  const classes = useStyles();
  const { onError } = useContext(FlashContext);


  const params = useParams();
  const from = +(params.from || 0);
  const max = 20;

  const [keys, setKeys] = useState(undefined);
  useEffect(() => {
    api.keyList(from, max).then((keys) => {
      setKeys(keys);
    }).catch((err) => {
      console.error(err);
      onError("Failed to retreive the list of keys");
    })
  }, [from, onError]);

  const nextLink = `/keys/${from+max}`;
  const prevLink = from >= max ? `/keys/${from-max}` : null;

  return (<div className={classes.root}>

    <div className={classes.pageTitle}>Key List</div>

    { keys ?
      
      <div className={classes.table}>
        <div className={classes.pagination}>
          <OYPagination prevLink={prevLink} nextLink={nextLink}>
          </OYPagination>
        </div>
        <OYTable>
          <OYTableHeader>
            <OYTableHeaderCell width={220} caption="Key Id"></OYTableHeaderCell>
            <OYTableHeaderCell width={40} caption="Key#"></OYTableHeaderCell>
            <OYTableHeaderCell width={160} caption="Activated (UTC)"></OYTableHeaderCell>
            <OYTableHeaderCell width={380} caption="Comment"></OYTableHeaderCell>
            <OYTableHeaderCell width={50} caption="Image"></OYTableHeaderCell>
            <OYTableHeaderCell width={120} caption="Actions"></OYTableHeaderCell>
          </OYTableHeader>
          <OYTableBody>
            { keys.map((key, i) => {
              return (
                <OYTableRow key={i}>
                  <OYTableTextCell caption={key.keyId}/>
                  <OYTableTextCell caption={key.keyNum}/>
                  <OYTableTextCell caption={key.firstDropTimestamp ? `${key.firstDropTimestamp.substring(0, 10)} ${key.firstDropTimestamp.substring(11, 19)}` : ""}/>
                  <OYTableTextCell caption={key.comment}/>
                  <OYTableImageCell width={40} height={40} src={api.renditionURL(key.imageId, "thumb", 40, 40)}/>
                  <OYTableCell>
                    <ActionList>
                      <Link to={`/key/${key.keyId}`}>
                        <Action image="eye.svg" caption="Display Key details"></Action>
                      </Link>
                    </ActionList>
                  </OYTableCell>
                </OYTableRow>
              );
            })}
          </OYTableBody>
        </OYTable>
      </div>

  : undefined }

  </div>);
};

export default KeyList;

