import React, { useEffect, useState } from 'react';
import {createUseStyles} from 'react-jss'
import OYKPI from './OYKPI';
import OYAKPIBar from './OYKPIBar';
import OYTable from './OYTable';
import OYTableHeader from './OYTableHeader';
import OYTableHeaderCell from './OYTableHeaderCell';
import OYTableRow from './OYTableRow';
import OYTableTextCell from './OYTableTextCell';
import OYTableImageCell from './OYTableImageCell';
import api from './API';
import OYTableCell from './OYTableCell';
import { Link, useParams } from 'react-router-dom';
import OYTableText from './OYTableText';
import OYPagination from './OYPagination';
import OYTextInput from './OYTextInput';
import OYSearch from './OYSearch';
import OYLoading from './OYLoading';
import OYTableBody from './OYTableBody';

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  pageTitle: {
    marginTop: "14px",
    marginBottom: "4px",
    marginLeft: "8px",
    fontSize: "30px",
    textTransform: "uppercase",
    color: "#00B38F",
  },
  kpis: {
    marginTop: "20px",
    marginBottom: "40px",
    marginLeft: "8px",
  },
  table: {
    margin: "8px",
  },
  filters: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FFF",
    borderTop: "1px solid #eee",
    borderBottom: "1px solid #ccc",
    marginTop: "8px",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "18px",
    paddingRight: "8px",
  },
  filter: {
    marginRight: "16px",
  },
  filterCaption: {
    fontSize: "10px"
  }
});

const EventLog = (props) => {
  const classes = useStyles();
  const params = useParams();

  const from = +(params.from || 0);
  const max = 200;

  const [dayFilter, setDayFilter] = useState([]);
  const [keyFilter, setKeyFilter] = useState([]);

  const [events, setEvents] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const events = await api.events(from, max, dayFilter, keyFilter);
      setEvents(events);
    }
    fetchData();
  }, [from, max, dayFilter, keyFilter])

  const [eventStats, setEventStats] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const eventStats = await api.eventStats({});
      setEventStats(eventStats);
    }
    fetchData();
  }, [])

  const nextLink = `/events/${from+max}`;
  const prevLink = from >= max ? `/events/${from-max}` : null;

  const onKey = (term) => {
    setKeyFilter(term);
  }

  const onDay = (term) => {
    setDayFilter(term);
  }

  return (<div className={classes.root}>

    <div className={classes.pageTitle}>Event Log</div>

    <div className={classes.filters}>
      <div className={classes.filter}>
        <div className={classes.filterCaption}>Event date (YYYY-MM-DD)</div>
        <OYTextInput onValidate={onDay}></OYTextInput>
      </div>
      <div className={classes.filter}>
        <div className={classes.filterCaption}>Search key# or key id</div>
        <OYSearch onValidate={onKey}></OYSearch>
      </div>
    </div>

    <div className={classes.kpis}>
      <OYAKPIBar>
        <OYKPI value={eventStats.eventsCount} unit={"events"}/>
        <OYKPI value={eventStats.activeKeys} unit={"keys"}/>
        <OYKPI value={eventStats.commentsCount} unit={"comments"}/>
        <OYKPI value={eventStats.imagesCount} unit={"images"}/>
        <OYKPI value={eventStats.reportsCount} unit={"reports"} flavor="error"/>
      </OYAKPIBar>
    </div>

    { !events ? <OYLoading/> : undefined }
    { events ?
    <div className={classes.table}>
      <OYPagination prevLink={prevLink} nextLink={nextLink}>
      </OYPagination>
      <OYTable>
        <OYTableHeader>
          <OYTableHeaderCell width={70} caption="Day"></OYTableHeaderCell>
          <OYTableHeaderCell width={90} caption="Hour (UTC)"></OYTableHeaderCell>
          <OYTableHeaderCell width={60} caption="Key#"></OYTableHeaderCell>
          <OYTableHeaderCell width={160} caption="Nick name"></OYTableHeaderCell>
          <OYTableHeaderCell width={120} caption="Event type"></OYTableHeaderCell>
          <OYTableHeaderCell width={380} caption="Comment"></OYTableHeaderCell>
          <OYTableHeaderCell width={60} caption="Image"></OYTableHeaderCell>
        </OYTableHeader>
        <OYTableBody>
          { events.map((event, i) => {
            return (
              <OYTableRow key={i}>
                <OYTableTextCell caption={event.eventTimestamp.substring(0, 10)}/>
                <OYTableTextCell caption={event.eventTimestamp.substring(11, 19)}/>
                <OYTableCell>
                  <Link to={`/key/${event.key.keyId}`}>
                    <OYTableText caption={event.key.keyNum}/>
                  </Link>
                </OYTableCell>
                <OYTableTextCell caption={event.user ? event.user.nickName : ""}/>
                <OYTableTextCell caption={event.eventType}/>
                <OYTableTextCell caption={event.comment}/>
                <OYTableImageCell src={api.renditionURL(event.imageId, "thumb", 32, 32)}/>
              </OYTableRow>
            );
          })}
        </OYTableBody>
      </OYTable>
    </div>
    : undefined }

  </div>);
};

export default EventLog;


