import React from "react";

export const FlashContext = React.createContext();

const FlashProvider = (props) => {
    return (
        <FlashContext.Provider value={{ onError:props.onError, onFlash:props.onFlash }}>
            {props.children}
        </FlashContext.Provider>
    )
}

export default FlashProvider;
