import React from 'react';
import {createUseStyles} from 'react-jss'
import { Marker, Popup } from 'react-leaflet';

const useStyles = createUseStyles({
  root: {
  },
  popup: {
    display: "flex",
    flexDirection: "column",
  },
  popupTitle: {
    marginBottom: "4px"
  },
  popupTimestamp: {
    color: "#AAA"
  }
});

const EventMarker = ({ event }) => {
  const classes = useStyles();
  if (!event || !event.latitude || !event.longitude) return null;
  return (
    <Marker position={[ event.latitude, event.longitude ]}>
      <Popup>
        <div className={classes.popup}>
          <div className={classes.popupTitle}>
            { (event.user && event.user.nickName ? event.user.nickName : "Someone") + " found the key" }
          </div>
          <div className={classes.popupTimestamp}>
            { event.eventTimestamp.substring(0, 10) }
          </div>
        </div>
      </Popup>
    </Marker>
  );
};

export default EventMarker;


