/*
 * (C) Copyright 2021 Open Your [...] (http://openyour.art/) and others.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Contributors:
 *     Alexandre Morin
 */
import React from 'react';
import {createUseStyles} from 'react-jss'

/**
 * Lays out multiple KPIs horizontally, adding padding between each KPI.
 */
const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
  },
});

const OYKPIBar = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      { props.children }
    </div>);
};

export default OYKPIBar;


