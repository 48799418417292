import React from 'react';
import {createUseStyles} from 'react-jss'
import api from './API';
const pjson = require('../package.json');

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
    backgroundColor: "#005242",
    color: "#DED",
    height: "35px",
    paddingLeft: "40px",
    paddingTop: "2px",
  },

  logo: {
    marginLeft: "-24px",
    marginRight: "32px",
    marginTop: "-8px",
    "& img": {
      height: "44px"
    }
  },
  title: {
    fontWeight: 800,
    fontStyle: "normal",
    fontSize: "28px",
    letterSpacing: "0.08em",
    textTransform: "uppercase",
    lineHeight: "32px"
  },

  a: {
    "&:link": {
      color: "#DED",
      textDecoration: "none",
      overflow: "hidden",
      whiteSpace: "nowrap",  
    },
    "&:visited": {
      color: "#DED",
      textDecoration: "none",
      overflow: "hidden",
      whiteSpace: "nowrap",  
    },
    "&:hover": {
      color: "#DED",
      textDecoration: "none",
      overflow: "hidden",
      whiteSpace: "nowrap",  
    },
    "&:active": {
      color: "#DED",
      textDecoration: "none",
      overflow: "hidden",
      whiteSpace: "nowrap",  
    },
  },
  gutter: {
    flexGrow: 1
  },
  version: {
    fontSize: 12,
    marginRight: 12,
    lineHeight: "12px",
    marginTop: 4,
    color: "#DDEEDD88"
  },
  operator: {
    display: "flex",
    flexDirection: "row",
    width: "90px",
    height: "30px",
    marginRight: "24px",
    "& img": {
      width: "30px",
      height: "30px",  
    }
  },
  operatorName: {
    fontSize: 12,
    marginLeft: "4px",
    lineHeight: "40px"

  }
});

const OYAppBar = ({ logo, caption, version, operatorName, operatorAvatar }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      { logo ?
        <div className={classes.logo}>
          <img src={logo} alt="Logo"/>
        </div>
      : undefined }
      <div className={classes.title}>
        <a className={classes.a} href="/">{caption}</a>
      </div>
      <div className={classes.gutter}></div>
      { operatorName || operatorAvatar ? 
      <div className={classes.operator}>
        { operatorAvatar ?
          <img src={operatorAvatar} alt="avatar"></img>
        : undefined }
        { operatorName ?
          <div className={classes.operatorName}>{operatorName}</div>
        : undefined }
      </div>
      : undefined }
      <div className={classes.version}>
        <div>{ version ? "Server "  + version : "\u00A0" }</div>
        <div>Client {pjson.version}</div>
      </div>
    </div>);
};

export default OYAppBar;

