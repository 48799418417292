/*
 * (C) Copyright 2021 Open Your [...] (http://openyour.art/) and others.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Contributors:
 *     Alexandre Morin
 */
import React from 'react';
import {createUseStyles} from 'react-jss'
import OYSwitch from './OYSwitch';
import OYTextInput from './OYTextInput';
import OYButton from './OYButton';
import OYButtonGroup from './OYButtonGroup';
import OYButtonBar from './OYButtonBar';
import OYKPI from './OYKPI';
import OYKPIBar from './OYKPIBar';
import OYPasswordInput from './OYPasswordInput';
import OYTable from './OYTable';
import OYTableHeader from './OYTableHeader';
import OYTableHeaderCell from './OYTableHeaderCell';
import OYTableBody from './OYTableBody';
import OYTableRow from './OYTableRow';
import OYTableTextCell from './OYTableTextCell';
import OYTableImageCell from './OYTableImageCell';
import OYTableCell from './OYTableCell';
import OYSearch from './OYSearch';
import OYPagination from './OYPagination';
import OYLoading from './OYLoading';
import OYAppBar from './OYAppBar';
import OYFlashMessage from './OYFlashMessage';
import OYProgressBar from './OYProgressBar';


const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  section: {
    display: "flex",
    flexDirection: "column"
  },
  sectionTitle: {
    marginTop: "14px",
    marginBottom: "4px",
    marginLeft: "8px",
    fontSize: "30px",
    textTransform: "uppercase",
    color: "#00B38F",
    paddingBottom: "4px",
    borderBottom: "1px solid #CCC",
  },
  sectionBody: {
    display: "flex",
    flexDirection: "column",
    marginTop: "16px",
    marginLeft: "24px",
    marginRight: "16px",
  },
  subSection: {
    marginBottom: "24px",
  },
  myCustomCell: {
    fontSize: 12,
    padding: 4
  },
  myCustomCellPrimary: {
    color: "#00B38F",
    fontWeight: "bold"
  },
  myCustomCellSecondary: {
    color: "#ccc",
  },
});

const Section = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <div className={classes.sectionTitle}>{props.title}</div>
      <div className={classes.sectionBody}>
        { props.children }
      </div>
    </div>
  );
}

const SubSection = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.subSection}>
        { props.children }
    </div>
  );
}

const OYButtonSection = (props) => {
  return (
    <Section title={"OYButton"}>

      <SubSection>
        <p>A regular button (click me)</p>
        <OYButton caption={"Hello"} onClick={() => alert("I've been clicked")}/>
      </SubSection>

      <SubSection>
        <p>A button with an image (click me)</p>
        <OYButton image={"/hall-of-fame.png"} onClick={() => alert("I've been clicked")}/>
      </SubSection>

      <SubSection>
        <p>A disabled button (click is disabled)</p>
        <OYButton caption={"Disabled"} disabled={true}/>
      </SubSection>

    </Section>
  );
}



const OYButtonGroupSection = (props) => {
  return (
    <Section title={"OYButtonGroup"}>

      <SubSection>
        <p>An horizontal button group</p>
        <OYButtonGroup>
          <OYButton caption={"First"}/>
          <OYButton caption={"Second"}/>
          <OYButton caption={"Third"}/>
        </OYButtonGroup>
      </SubSection>

      <SubSection>
        <p>An vertical button group</p>
        <OYButtonGroup layout="vertical">
          <OYButton caption={"First"}/>
          <OYButton caption={"Second"}/>
          <OYButton caption={"Third"}/>
        </OYButtonGroup>
      </SubSection>

    </Section>
  );
}



const OYButtonBarSection = (props) => {
  return (
    <Section title={"OYButtonBar"}>

      <SubSection>
        <p>An horizontal button bar</p>
        <OYButtonBar>
          <OYButtonGroup>
            <OYButton caption={"First"}/>
            <OYButton caption={"Second"}/>
            <OYButton caption={"Third"}/>
          </OYButtonGroup>
          <OYButtonGroup>
            <OYButton caption={"Four"}/>
            <OYButton caption={"Five"}/>
            <OYButton caption={"Six"}/>
          </OYButtonGroup>
        </OYButtonBar>
      </SubSection>

      <SubSection>
        <p>An vertical button bar</p>
        <p>Note that child OYButtonGroup elements also have their layout set to vertical</p>
        <OYButtonBar layout="vertical">
          <OYButtonGroup layout="vertical">
            <OYButton caption={"First"}/>
            <OYButton caption={"Second"}/>
            <OYButton caption={"Third"}/>
          </OYButtonGroup>
          <OYButtonGroup layout="vertical">
            <OYButton caption={"Four"}/>
            <OYButton caption={"Five"}/>
            <OYButton caption={"Six"}/>
          </OYButtonGroup>
        </OYButtonBar>
      </SubSection>

    </Section>
  );
}


const OYKPISection = (props) => {
  return (
    <Section title={"OYKPI"}>

      <SubSection>
        <p>A basic KPI with a value and a unit</p>
        <OYKPI value={1234} unit={"km"} />
      </SubSection>

      <SubSection>
        <p>A basic KPI with the "error" flavor</p>
        <OYKPI value={1234} unit={"km"} flavor={"error"}/>
      </SubSection>

      <SubSection>
        <p>A basic KPI without a unit</p>
        <OYKPI value={1234} />
      </SubSection>

      <SubSection>
        <p>A KPI with a caption</p>
        <OYKPI value={1234} unit={"km"} caption="Distance travelled"/>
      </SubSection>

    </Section>
  );
}



const OYSwitchSection = (props) => {
  return (
    <Section title={"OYSwitch"}>

      <SubSection>
        <p>A default switcher without options (click me)</p>
        <OYSwitch onClick={(value) => alert(`I've been clicked and I'm ${value}`)}/>
      </SubSection>

      <SubSection>
        <p>Initial state is true</p>
        <OYSwitch defaultValue={true} onClick={(value) => alert(`I've been clicked and I'm ${value}`)}/>
      </SubSection>

      <SubSection>
        <p>A default switcher without options</p>
        <OYSwitch captionTrue={"ON"} captionFalse={"OFF"} />
      </SubSection>

      <SubSection>
        <p>Various sizes</p>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <div style={{ paddingRight: "12px" }}>
            <OYSwitch size="XS" captionFalse={"XS"}/>
          </div>
          <div style={{ paddingRight: "12px" }}>
            <OYSwitch size="S" captionFalse={"XS"}/>
          </div>
          <div style={{ paddingRight: "12px" }}>
            <OYSwitch size="M" captionFalse={"M"}/>
          </div>
          <div style={{ paddingRight: "12px" }}>
            <OYSwitch size="L" captionFalse={"L"}/>
          </div>
          <div style={{ paddingRight: "12px" }}>
            <OYSwitch size="XL" captionFalse={"XL"}/>
          </div>
        </div>
      </SubSection>

      <SubSection>
        <p>A switcher with multiple options (no default value)</p>
        <OYSwitch size="M" captions={ [ "One", "Two", "Three", "Four" ]} values={ [ 1, 2, 3, 4] } onClick={(value) => alert(`I've been clicked and I'm ${value}`)}/>
      </SubSection>

      <SubSection>
        <p>A switcher with multiple options and a default value</p>
        <OYSwitch size="M" defaultValue={2} captions={ [ "One", "Two", "Three", "Four" ]} values={ [ 1, 2, 3, 4] } onClick={(value) => alert(`I've been clicked and I'm ${value}`)}/>
      </SubSection>

    </Section>
  );
}



const OYTextInputSection = (props) => {
  return (
    <Section title={"OYTextInput"}>

      <SubSection>
        <p>A simple text input let one type or edit text</p>
        <OYTextInput></OYTextInput>
      </SubSection>

      <SubSection>
        <p>A simple text input let one type or edit text, with default value</p>
        <OYTextInput defaultValue={"Hello"}></OYTextInput>
      </SubSection>

      <SubSection>
        <p>Press the ENTER key to validate the text</p>
        <OYTextInput onValidate={(value) => alert(`I've been validated with ${value}`)}></OYTextInput>
      </SubSection>

      <SubSection>
        <p>A multi row input</p>
        <OYTextInput rows={4}></OYTextInput>
      </SubSection>

    </Section>
  );
}



const OYPasswordInputSection = (props) => {
  return (
    <Section title={"OYPasswordInput"}>

      <SubSection>
        <p>A simple text input let one type or edit text</p>
        <OYPasswordInput></OYPasswordInput>
      </SubSection>

      <SubSection>
        <p>Press the ENTER key to validate the text</p>
        <OYPasswordInput onValidate={(value, score) => alert(`I've been validated with ${value} and strength score is ${score}`)}></OYPasswordInput>
      </SubSection>

    </Section>
  );
}


const OYKPIBarSection = (props) => {
  return (
    <Section title={"OYKPIBar"}>

      <SubSection>
        <p>A simple KPI Bar with multipe KPIs</p>
        <OYKPIBar>
          <OYKPI value={47.3} unit={"km"} />
          <OYKPI value={1234} unit={"events"}/>
          <OYKPI value={4} unit={"error"} flavor="error"/>
        </OYKPIBar>
      </SubSection>

    </Section>
  );
}



const OYTableSection = (props) => {
  const classes = useStyles();
  return (
    <Section title={"OYTable"}>

      <SubSection>
        <p>A simple table with text and image cells</p>
        <OYTable>
          <OYTableHeader>
            <OYTableHeaderCell caption="Tree"></OYTableHeaderCell>
            <OYTableHeaderCell caption="Latin"></OYTableHeaderCell>
            <OYTableHeaderCell caption="Leaf"></OYTableHeaderCell>
          </OYTableHeader>
          <OYTableBody>
            <OYTableRow>
                <OYTableTextCell caption="Birch"></OYTableTextCell>
                <OYTableTextCell caption="Betula sp."></OYTableTextCell>
                <OYTableImageCell src="/birch.png"></OYTableImageCell>
              </OYTableRow>
              <OYTableRow>
                <OYTableTextCell caption="Beech"></OYTableTextCell>
                <OYTableTextCell caption="Fagus Sylvatica"></OYTableTextCell>
                <OYTableImageCell src="/beech.png"></OYTableImageCell>
              </OYTableRow>
              <OYTableRow>
                <OYTableTextCell caption="Lime"></OYTableTextCell>
                <OYTableTextCell caption="Tilia sp."></OYTableTextCell>
                <OYTableImageCell src="/lime.png"></OYTableImageCell>
              </OYTableRow>
              <OYTableRow>
                <OYTableTextCell caption="Sycamore"></OYTableTextCell>
                <OYTableTextCell caption="Acer Pseudoplatanus"></OYTableTextCell>
                <OYTableImageCell src="/sycamore.png"></OYTableImageCell>
              </OYTableRow>
            </OYTableBody>
          </OYTable>
      </SubSection>

      <SubSection>
        <p>A simple table with custom column widths</p>
        <OYTable>
          <OYTableHeader>
            <OYTableHeaderCell width={120} caption="Tree"></OYTableHeaderCell>
            <OYTableHeaderCell width={180} caption="Latin"></OYTableHeaderCell>
            <OYTableHeaderCell width={40} caption="Leaf"></OYTableHeaderCell>
          </OYTableHeader>
          <OYTableBody>
            <OYTableRow>
                <OYTableTextCell caption="Birch"></OYTableTextCell>
                <OYTableTextCell caption="Betula sp."></OYTableTextCell>
                <OYTableImageCell src="/birch.png"></OYTableImageCell>
              </OYTableRow>
              <OYTableRow>
                <OYTableTextCell caption="Beech"></OYTableTextCell>
                <OYTableTextCell caption="Fagus Sylvatica"></OYTableTextCell>
                <OYTableImageCell src="/beech.png"></OYTableImageCell>
              </OYTableRow>
              <OYTableRow>
                <OYTableTextCell caption="Lime"></OYTableTextCell>
                <OYTableTextCell caption="Tilia sp."></OYTableTextCell>
                <OYTableImageCell src="/lime.png"></OYTableImageCell>
              </OYTableRow>
              <OYTableRow>
                <OYTableTextCell caption="Sycamore"></OYTableTextCell>
                <OYTableTextCell caption="Acer Pseudoplatanus"></OYTableTextCell>
                <OYTableImageCell src="/sycamore.png"></OYTableImageCell>
              </OYTableRow>
            </OYTableBody>
          </OYTable>
      </SubSection>

      <SubSection>
        <p>A simple table with links : click on a leaf to know more about the tree</p>
        <OYTable>
          <OYTableHeader>
            <OYTableHeaderCell width={120} caption="Tree"></OYTableHeaderCell>
            <OYTableHeaderCell width={180} caption="Latin"></OYTableHeaderCell>
            <OYTableHeaderCell width={40} caption="Leaf"></OYTableHeaderCell>
          </OYTableHeader>
          <OYTableBody>
            <OYTableRow>
                <OYTableTextCell caption="Birch"></OYTableTextCell>
                <OYTableTextCell caption="Betula sp."></OYTableTextCell>
                <OYTableImageCell src="/birch.png" href="https://en.wikipedia.org/wiki/Birch"></OYTableImageCell>
              </OYTableRow>
              <OYTableRow>
                <OYTableTextCell caption="Beech"></OYTableTextCell>
                <OYTableTextCell caption="Fagus Sylvatica"></OYTableTextCell>
                <OYTableImageCell src="/beech.png" href="https://en.wikipedia.org/wiki/Beech"></OYTableImageCell>
              </OYTableRow>
              <OYTableRow>
                <OYTableTextCell caption="Lime"></OYTableTextCell>
                <OYTableTextCell caption="Tilia sp."></OYTableTextCell>
                <OYTableImageCell src="/lime.png" href="https://en.wikipedia.org/wiki/Tilia"></OYTableImageCell>
              </OYTableRow>
              <OYTableRow>
                <OYTableTextCell caption="Sycamore"></OYTableTextCell>
                <OYTableTextCell caption="Acer Pseudoplatanus"></OYTableTextCell>
                <OYTableImageCell src="/sycamore.png" href="https://en.wikipedia.org/wiki/Acer_pseudoplatanus"></OYTableImageCell>
              </OYTableRow>
            </OYTableBody>
          </OYTable>
      </SubSection>

      <SubSection>
        <p>Custom cells</p>
        <OYTable>
          <OYTableHeader>
            <OYTableHeaderCell width={200} caption="Tree"></OYTableHeaderCell>
            <OYTableHeaderCell width={40} caption="Leaf"></OYTableHeaderCell>
          </OYTableHeader>
          <OYTableBody>
            <OYTableRow>
                <OYTableCell>
                  <div className={classes.myCustomCell}>
                    <div className={classes.myCustomCellPrimary}>Birch</div>
                    <div className={classes.myCustomCellSecondary}>Betula sp.</div>
                  </div>
                </OYTableCell>
                <OYTableImageCell src="/birch.png" href="https://en.wikipedia.org/wiki/Birch"></OYTableImageCell>
              </OYTableRow>
              <OYTableRow>
                <OYTableCell>
                  <div className={classes.myCustomCell}>
                    <div className={classes.myCustomCellPrimary}>Beech</div>
                    <div className={classes.myCustomCellSecondary}>Fagus Sylvatica</div>
                  </div>
                </OYTableCell>
                <OYTableImageCell src="/beech.png" href="https://en.wikipedia.org/wiki/Beech"></OYTableImageCell>
              </OYTableRow>
              <OYTableRow>
                <OYTableCell>
                  <div className={classes.myCustomCell}>
                    <div className={classes.myCustomCellPrimary}>Lime</div>
                    <div className={classes.myCustomCellSecondary}>Tilia sp.</div>
                  </div>
                </OYTableCell>
                <OYTableImageCell src="/lime.png" href="https://en.wikipedia.org/wiki/Tilia"></OYTableImageCell>
              </OYTableRow>
              <OYTableRow>
              <OYTableCell>
                  <div className={classes.myCustomCell}>
                    <div className={classes.myCustomCellPrimary}>Sycamore</div>
                    <div className={classes.myCustomCellSecondary}>Acer Pseudoplatanus</div>
                  </div>
                </OYTableCell>
                <OYTableImageCell src="/sycamore.png" href="https://en.wikipedia.org/wiki/Acer_pseudoplatanus"></OYTableImageCell>
              </OYTableRow>
            </OYTableBody>
          </OYTable>
      </SubSection>

    </Section>
  );
}


const OYSearchSection = (props) => {
  return (
    <Section title={"OYSearch"}>

      <SubSection>
        <p>A simple text input let one type or edit text</p>
        <OYSearch></OYSearch>
      </SubSection>

      <SubSection>
        <p>A simple text input let one type or edit text, with default value</p>
        <OYSearch defaultValue={"Hello"}></OYSearch>
      </SubSection>

      <SubSection>
        <p>Press the ENTER key to validate the text</p>
        <OYSearch onValidate={(value) => alert(`I've been validated with ${value}`)}></OYSearch>
      </SubSection>

    </Section>
  );
}


const OYPaginationSection = (props) => {
  return (
    <Section title={"OYPagination"}>

      <SubSection>
        <p>Basic pagination example</p>
        <OYPagination></OYPagination>
      </SubSection>

      <SubSection>
        <p>Basic pagination example with enable next and previous links</p>
        <OYPagination prevLink="#" nextLink="#" ></OYPagination>
      </SubSection>

    </Section>
  );
}



const OYLoadingSection = (props) => {
  return (
    <Section title={"OYLoading"}>

      <SubSection>
        <p>Simple example</p>
        <OYLoading></OYLoading>
      </SubSection>

    </Section>
  );
}



const OYAppBarSection = (props) => {
  return (
    <Section title={"OYAppBar"}>

      <SubSection>
        <p>App bar with no props</p>
        <OYAppBar></OYAppBar>
      </SubSection>

      <SubSection>
        <p>App bar with logo and caption</p>
        <OYAppBar logo="/logo.png" caption="OPEN YOUR […]"></OYAppBar>
      </SubSection>

      <SubSection>
        <p>App bar with all props</p>
        <OYAppBar logo="/logo.png" caption="OPEN YOUR […]" version="1.2.3" operatorName="alex" operatorAvatar="/hall-of-fame.png"></OYAppBar>
      </SubSection>

    </Section>
  );
}



const OYFlashMessageSection = (props) => {
  return (
    <Section title={"OYFlashMessage"}>

      <SubSection>
        <p>Simple informational message</p>
        <OYFlashMessage position="inline" message="Hello, world"></OYFlashMessage>
      </SubSection>

      <SubSection>
        <p>Error message</p>
        <OYFlashMessage position="inline" level="error" message="Failed to contact server"></OYFlashMessage>
      </SubSection>

    </Section>
  );
}



const OYProgressBarSection = (props) => {
  return (
    <Section title={"OYProgressBar"}>

      <SubSection>
        <p>Progress bar (0%)</p>
        <OYProgressBar></OYProgressBar>
      </SubSection>

      <SubSection>
        <p>Progress bar (33%)</p>
        <OYProgressBar progress={.33}></OYProgressBar>
      </SubSection>

      <SubSection>
        <p>Progress bar (100%)</p>
        <OYProgressBar progress={1}></OYProgressBar>
      </SubSection>

    </Section>
  );
}



const Zoo = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <OYButtonSection/>
      <OYButtonGroupSection/>
      <OYButtonBarSection/>
      <OYKPISection/>
      <OYKPIBarSection/>
      <OYSwitchSection/>
      <OYTextInputSection/>
      <OYPasswordInputSection/>
      <OYTableSection/>
      <OYSearchSection/>
      <OYPaginationSection/>
      <OYLoadingSection/>
      <OYAppBarSection/>
      <OYFlashMessageSection/>
      <OYProgressBarSection/>
    </div>
  );
};

export default Zoo;


