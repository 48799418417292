import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import {createUseStyles} from 'react-jss'
import api from './API';
import { FlashContext } from './FlashProvider';
import { OperatorContext } from './OperatorContext';
import OYButton from './OYButton';
import OYPasswordInput from './OYPasswordInput';
import OYTextInput from './OYTextInput';

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  pageTitle: {
    marginTop: "14px",
    marginBottom: "4px",
    marginLeft: "8px",
    fontSize: "30px",
    textTransform: "uppercase",
    color: "#00B38F",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "500px",
    margin: "auto",
    marginTop: "40px",
  },
  control: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "24px",
  },
  avatar: {
    display: "flex",
    flexDirection: "row",
    width: "96px",
    height: "96px",
    "& img": {
      width: "96px",
      height: "96px",  
    }
  },
  submit: {
    marginTop: "24px",
    marginLeft: "210px"
  },
  password: {
  },
  passwordquiet: {
    color: "#CCC"
  },
  passworderror: {
    color: "rgb(179, 0, 143)",
  },
  passwordinfo: {
    color: "#00B38F",
  }
});

const Profile = (props) => {
  const classes = useStyles();
  const { onError, onFlash } = useContext(FlashContext);

  const { operator, setOperator } = useContext(OperatorContext);
  const noComment = { text: "Password will not be changed", style: "quiet", ok: true }
  
  const [ password, setPassword ] = useState("");
  const [ name, setName ] = useState(operator.name);
  const [ passwordComment, setPasswordComment ] = useState(noComment);

  const onNameChange = (text) => {
    setName(text);
  }

  const onPasswordChange = (text, score) => {
    setPassword(text);
    if (!text)
      setPasswordComment(noComment);
    else if (text.toLowerCase() === "neo")
      setPasswordComment({ ok: false, text: "Seriously ?!", style: "error" });
    else if (text.toLowerCase() === "pass" || text.toLowerCase() === "password" || text.toLowerCase() === "1234" || text.toLowerCase() === "123456")
      setPasswordComment({ ok: false, text: "LOL !", style: "error" });
    else if (score < 2)
      setPasswordComment({ ok: false, text: "Password is trivial", style: "error" });
    else if (score < 4)
      setPasswordComment({ ok: false, text: "Password is not complex enough", style: "error" });
    else if (text.length < 12)
      setPasswordComment({ ok: false, text: "Password is too short (min 12 characters)", style: "error" });
    else
      setPasswordComment({ ok: true, text: "Password will be changed", style: "info" });
  }

  const onSubmit = () => {
    const profile = {
      name: name,
      password: password
    }
    api.updateProfile(profile).then((operator) => {
      onFlash("Profile updated");
      setOperator(operator);
    }).catch((err) => {
      console.error(err);
      onError("Failed to update profile");
    })
  }


  return (<div className={classes.root}>
    <div className={classes.pageTitle}>Change Operator Profile</div>

    <div className={classes.form}>

      <div className={classes.control}>
        <div className={classes.avatar}>
          <img src={api.renditionURL(operator.avatar, "thumb", 96, 96)} alt="avatar"></img>
        </div>
      </div>

      <div className={classes.control}>
        <div className={classes.label}>Name:</div>
        <OYTextInput defaultValue={name} onChange={onNameChange}></OYTextInput>
      </div>

      <div className={classes.control}>
        <div className={classes.label}>Change password:</div>
        <OYPasswordInput onChange={onPasswordChange}></OYPasswordInput>
        <div className={`${classes.password} ${classes["password" + passwordComment.style]}`}>{passwordComment.text}</div>
      </div>

      <div className={classes.control}>
        <div className={classes.submit}>
          <OYButton disabled={!passwordComment.ok} caption="Submit" onClick={onSubmit}></OYButton>
        </div>
      </div>

    </div>
  </div>);
};

export default Profile;
