/*
 * (C) Copyright 2021 Open Your [...] (http://openyour.art/) and others.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Contributors:
 *     Alexandre Morin
 */
import React, { useState } from 'react';
import {createUseStyles} from 'react-jss'

/**
 * A progress bar
 */
const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "row"
  },
  bar: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid #707070",
    fontSize: "12px",
    width: "307px",
    height: "24px",
    lineHeight: "34px",
    color: "#F8F8F8",
    boxSizing: "border-box",
    //paddingLeft: "8px",
    //paddingRight: "8px",
  },
  barLeft: {
    backgroundColor: "#00B38F",
    //width: "25%",
  },
  barRight: {
    backgroundColor: "#6B8091",
    //width: "75%",
  },
  barXS: {
    width: "80px",
    height: "12px",
  },
  barS: {
    width: "120px",
    height: "14px",
  },
  barM: {
    width: "160px",
    height: "16px",
  },
  barL: {
    width: "200px",
    height: "18px",
  },
  barXL: {
    width: "280px",
    height: "20px",
  },

});

const OYProgressBar = ({ progress, size }) => {
  const classes = useStyles();

  const left = Math.round(100 * (progress||0) );
  const right = 100 - left;
  size = size || "XS";

  return (<div className={classes.root}>
    <div className={`${classes.bar} ${classes["bar"+size]}`}>
      <div className={classes.barLeft} style={{ width:`${left}%` }}></div>
      <div className={classes.barRight} style={{ width:`${right}%` }}></div>
    </div>
  </div>);
};

export default OYProgressBar;


