/*
 * (C) Copyright 2021 Open Your [...] (http://openyour.art/) and others.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Contributors:
 *     Alexandre Morin
 */
import React from 'react';
import {createUseStyles} from 'react-jss'

/**
 * OpenYour button component
 */
const useStyles = createUseStyles({
  root: {
    border: "1px solid #00B38F",
    fontSize: "16px",
    width: "96px",
    height: "48px",
    lineHeight: "48px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#FFF",
    boxSizing: "border-box",
  },
  image: {
    marginTop: "3px",
    "& img": {
      width: "40px"
    }
  },
  enabled: {
    backgroundColor: "#00B38F",
    "&:hover": {
      backgroundColor: "#00B38F",
      color: "#FBEB26",
      border: "1px solid #FBEB26",
      cursor: "pointer",
    },
    "&:active": {
      backgroundColor: "#00936F",
      transition: "background-color .2s linear",
      border: "1px solid #FBEB26",
    },
  },
  disabled: {
    border: "1px solid #00B38F44",
    backgroundColor: "#00B38F44",
  }
});

const OYButton = ({ caption, onClick, image, disabled }) => {
  const classes = useStyles();
  const handleClick = () => {
    if (!disabled && onClick)
      onClick();
  }
  return (
    <button className={`${classes.root} ${disabled ? classes.disabled : classes.enabled}`} onClick={handleClick}>{caption}
     { image ? 
      <div className={classes.image}>
        <img src={image} alt={caption}/>
      </div>
     : undefined }
    </button>
  );
};

export default OYButton;


