import React, { useContext, useEffect, useState } from 'react';
import {createUseStyles} from 'react-jss'
import OYTable from './OYTable';
import OYTableHeader from './OYTableHeader';
import OYTableHeaderCell from './OYTableHeaderCell';
import OYTableRow from './OYTableRow';
import OYTableTextCell from './OYTableTextCell';
import OYTableImageCell from './OYTableImageCell';
import api from './API';
import OYTableBody from './OYTableBody';
import { FlashContext } from './FlashProvider';

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  pageTitle: {
    marginTop: "14px",
    marginBottom: "4px",
    marginLeft: "8px",
    fontSize: "30px",
    textTransform: "uppercase",
    color: "#00B38F",
  },
  table: {
    margin: "8px",
  },
  action: {
  }
});


const OperatorList = (props) => {
  const classes = useStyles();
  const { onError } = useContext(FlashContext);

  const [operators, setOperators] = useState(undefined);
  useEffect(() => {
    api.operatorList().then((operators) => {
      setOperators(operators);
    }).catch((err) => {
      console.error(err);
      onError("Failed to retreive the list of operators");
    })
  }, [onError]);

  return (<div className={classes.root}>

    <div className={classes.pageTitle}>Operator List</div>

    { operators ?
      
      <div className={classes.table}>
        <OYTable>
          <OYTableHeader>
            <OYTableHeaderCell width={220} caption="Operator Id"></OYTableHeaderCell>
            <OYTableHeaderCell width={160} caption="Name"></OYTableHeaderCell>
            <OYTableHeaderCell width={50} caption="Avatar"></OYTableHeaderCell>
          </OYTableHeader>
          <OYTableBody>
            { operators.map((operator, i) => {
              return (
                <OYTableRow key={i}>
                  <OYTableTextCell caption={operator.id}/>
                  <OYTableTextCell caption={operator.name}/>
                  <OYTableImageCell src={api.renditionURL(operator.avatar, "thumb", 32, 32)}/>
                </OYTableRow>
              );
            })}
          </OYTableBody>
        </OYTable>
      </div>

  : undefined }

  </div>);
};

export default OperatorList;

