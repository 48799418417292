/*
 * (C) Copyright 2021 Open Your [...] (http://openyour.art/) and others.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Contributors:
 *     Alexandre Morin
 */
import React, { useState } from 'react';
import {createUseStyles} from 'react-jss'

/**
 * Switches toggle the state of a single setting on or off.
 */
const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "row"
  },
  enabled: {
    border: "1px solid #00B38F",
    backgroundColor: "#00B38F",
    fontSize: "12px",
    width: "36px",
    height: "24px",
    lineHeight: "24px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#FFF",
    boxSizing: "border-box",
  },
  disabled: {
    border: "1px solid #00B38F44",
    backgroundColor: "#00B38F44",
    fontSize: "12px",
    height: "24px",
    lineHeight: "24px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#FFF",
    boxSizing: "border-box",
    "&:hover": {
      backgroundColor: "#00B38F",
      color: "#FBEB26",
      border: "1px solid #FBEB26",
      cursor: "pointer",
    },
    "&:active": {
      backgroundColor: "#00936F",
      transition: "background-color .2s linear",
      border: "1px solid #FBEB26",
    },
  },
  buttonXS: {
    width: "36px",
  },
  buttonS: {
    width: "48px",
  },
  buttonM: {
    width: "60px",
  },
  buttonL: {
    width: "84px",
  },
  buttonXL: {
    width: "108px",
  },
});

const OYSwitch = ({ defaultValue, captionTrue, captionFalse, onClick, captions, values, size }) => {
  const classes = useStyles();

  // Legacy mode: captionTrue and captionFalse are set
  if (captions === undefined) {
    captions = [ captionTrue || "Yes", captionFalse || "No" ];
    values = [ true, false ];
    if (defaultValue === undefined) 
      defaultValue = false;
  }

  const [value, setValue] = useState(defaultValue);

  const handleClick = (index) => {
    const newValue = values[index];
    if (value !== newValue) {
      setValue(newValue);
      if (onClick)
        onClick(newValue);
    }
  }

  size = size || "XS";

  const buttons = [];
  for (let i=0; i<captions.length; i++) {
    const button = <button className={ `${value === values[i] ? classes.enabled : classes.disabled} ${classes["button" + size]}` } 
                           onClick={ () => handleClick(i) }>{ captions[i] }</button>
    buttons.push(button);
  }

  return (
    <div className={classes.root}>
      { buttons }
    </div>
  );
};

export default OYSwitch;


