/*
 * (C) Copyright 2021 Open Your [...] (http://openyour.art/) and others.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Contributors:
 *     Alexandre Morin
 */
import React from 'react';
import {createUseStyles} from 'react-jss'

/**
 * A mutli-column table. 
 * There are multiple components invovlved in building a table: `OYTable`, `OYTableHeader`, `OYTableHeaderCell`, `OYTableBody`, `OYTableRow`, and `OYTableCell`. Furthermore, cells can be rendered with spetialized components `OYTableTextCell` and `OYTableImageCell`.
 * General structure
 * 
 *   <OYTable>
 *     <OYTableHeader>
 *       <OYTableHeaderCell/>
 *       ...
 *     </OYTableHeader>
 *     <OYTableBody>
 *       <OYTableRow>
 *         <OYTableCell/>
 *         ...
 *       </OYTableRow>
 *       ...
 *     </OYTableBody>
 *   </OYTable>
 */
const useStyles = createUseStyles({
  root: {
    "border-collapse": "collapse",
    border: "1px solid #EEE",
    backgroundColor: "#FFF"
  }
});

const OYTable = (props) => {
  const classes = useStyles()
  return (
    <table className={classes.root}>
      {props.children}
    </table>
  );
};

export default OYTable;


