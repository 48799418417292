/*
 * (C) Copyright 2021 Open Your [...] (http://openyour.art/) and others.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Contributors:
 *     Alexandre Morin
 */
import React, { useState } from 'react';
import {createUseStyles} from 'react-jss'

/**
 * Lets users enter and edit text
 */
const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "row"
  },
  input: {
    border: "1px solid #707070",
    fontSize: "12px",
    width: "307px",
    height: "24px",
    lineHeight: "34px",
    color: "#F8F8F8",
    backgroundColor: "#6B8091",
    boxSizing: "border-box",
    paddingLeft: "8px",
    paddingRight: "8px",
    "&:hover": {
      border: "1px solid #00B38F"
    },
    "&:focus": {
      border: "3px solid #00B38F"
    },
    "&:focus-visible": {
      "outline-color": "#00B38F",
      "outline-width": 0
    }
  },
  textarea: {
    paddingTop: "2px", 
    height: "72px",
    lineHeight: "14px",
  },
});

const OYTextInput = ({defaultValue, onChange, onValidate, rows}) => {
  const classes = useStyles()
  const [value, setValue] = useState(defaultValue);
  const handleChange = (event) => {
    const text = event.target.value;
    setValue(text);
    if (onChange) onChange(text);
  }
  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      if (onValidate) onValidate(value);
    }
  }

  let input;
  if (!rows || rows == 1) {
    input = <input type="text" className={classes.input} value={value || ""} onKeyUp={handleKeyUp} onChange={handleChange}></input>;
  }
  else {
    input = <textarea rows={rows} className={`${classes.input} ${classes.textarea}`} style={{ height: 18*rows }} value={value || ""} onKeyUp={handleKeyUp} onChange={handleChange}></textarea>;
  }

  return (<div className={classes.root}>
    { input }
  </div>);
};

export default OYTextInput;


