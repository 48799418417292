import React, { useContext, useEffect, useState } from 'react';
import {createUseStyles} from 'react-jss'
import OYKPI from './OYKPI';
import OYAKPIBar from './OYKPIBar';
import api from './API';
import { useNavigate, useParams } from "react-router-dom";
import OYSearch from './OYSearch';
import OYLoading from './OYLoading';
import KeyLocationMap from './KeyLocationMap';
import EventLocationMiniMap from './EventLocationMiniMap';
import { FlashContext } from './FlashProvider';
import AnimateHeight from 'react-animate-height';
import OYSwitch from './OYSwitch';


var QRCode = require('qrcode.react');

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  pageTitle: {
    marginTop: "14px",
    marginBottom: "4px",
    marginLeft: "8px",
    fontSize: "30px",
    textTransform: "uppercase",
    color: "#00B38F",
  },
  details: {
    width: "531px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
  },
  kpis: {
    marginTop: "20px",
    marginBottom: "40px",
  },
  qrcode: {
    position: "absolute",
    left: "240px",
    top: "180px",
    border: "1px solid #ccc",
    width: "130px",
    height: "130px",
  },
  section: {
    marginTop: "24px",
    backgroundColor: "#FFF",
    //border: "1px solid #eee",
    display: "flex",
    flexDirection: "column",
  },
  keyMap: {
    display: "flex",
    flexDirection: "row",
  },
  keyMapBody: {
    display: "flex",
    flexDirection: "column",
    width: "500px",
  },
  keyHeader: {
    display: "flex",
    flexDirection: "row",
  },
  keyHeaderBody: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #eee",
  },
  title: {
    paddingTop: "12px",
    paddingLeft: "12px",
    paddingRight: "12px",
    color: "#00B38F"
  },
  timestamp: {
    paddingLeft: "12px",
    paddingRight: "12px",
    color: "#AAA",
    fontSize: "10px",
    textAlign: "right"
  },
  keyImage: {
    width: "500px",
    height: "196px",
    "& img": {
      width: "500px",
      height: "196px",
    }
  },
  keyEvent: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F9F9F9",
    //border: "1px solid #eee",
  },
  keyEventBody: {
    border: "1px solid #eee",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    backgroundColor: "#FFF",
    borderRight: "1px solid #eee",
  },
  keyEventActions: {
    width: "32px",
    minWidth: "32px",
    //width: "31px",
    //minWidth: "31px",
    flexGrow: 0,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#F9F9F9",
  },
  keyEventActionsBody: {
    display: "flex",
    flexGrow: 0,
    flexDirection: "column",
    borderRight: "1px solid #eee",
    borderBottom: "1px solid #eee",
    borderTop: "1px solid #eee",
    backgroundColor: "#FFF",
    paddingTop: "4px",
    paddingBottom: "16px",
  },
  keyEventAction: {
    padding: "6px",
    width: "20px",
    height: "20px",
    "& img": {
      width: "20px",
      height: "20px",  
    },
    color: "#00B38F",
    fill: "#00B38F",
    opacity: .5,
    cursor: "pointer",
    "&:hover": {
      opacity: 1
    }  
  },
  keyEventTitle: {
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
  },
  keyEventNickName: {
    color: "#00B38F"
  },
  keyEventTitleText: {
    color: "#333",
    marginLeft: "8px"
  },
  keyEventMeta: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  eventTimestamp: {
    flexGrow: 1,
    padding: 0,
    color: "#AAA",
    fontSize: "10px",
    textAlign: "left"
  },
  eventLocation: {
    flexGrow: 1,
    padding: 0,
    color: "#AAA",
    fontSize: "10px",
    textAlign: "right"
  },
  keyEvenComment: {
    paddingLeft: "12px",
    paddingRight: "12px",
    color: "#333",
    marginTop: "8px",
    marginBottom: "18px",
  },
  keyEventImage: {
    padding: 0,
    "& img": {
      width: "500px",
      margin: 0,
      padding: 0,
    }
  },
  bottomPadding: {
    width: "100%",
    height: "48px"
  },
  filters: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FFF",
    borderTop: "1px solid #eee",
    borderBottom: "1px solid #ccc",
    marginTop: "8px",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "18px",
    paddingRight: "8px",
  },
  filter: {
    marginRight: "16px",
  },
  filterCaption: {
    fontSize: "10px"
  },
});

const Section = (props) => {
  const classes = useStyles();
  return (<div className={classes.section}>
    { props.children }
  </div>);
}

const KeyHeader = (props) => {
  const classes = useStyles();
  const key = props.oyKey;
  if (!key) return null;
  return (<div className={classes.keyHeader}>
    <div className={classes.keyHeaderBody}>
      <div className={classes.title} dangerouslySetInnerHTML={{ __html: `This is key #${key.keyNum}: ${key.comment}` }}></div>
      <div className={classes.timestamp}>{key.firstDropTimestamp_timestamp}</div>
      { key.imageId ? 
        <div className={classes.keyImage}>
          <img src={api.renditionURL(key.imageId, "image", 500, 196)} alt={"Key"}/>
        </div>
      : undefined }
    </div>
    <div className={classes.keyEventActions}>
    </div>
  </div>);
}

const KeyEventSection = (props) => {
  const key = props.oyKey;
  const event = props.event;
  const { onError } = useContext(FlashContext);

  const [deleted, setDeleted] = useState(false)
  const onDeleteEvent = () => {
    setDeleted(true);
    api.deleteEvent(event.eventId).then({
    }).catch((ex) => {
      onError("Failed to delete event");
      setDeleted(false);
    });
  }

  if (!key || !event)
    return null;

  return (
    <AnimateHeight duration={ 300 }  height={ deleted ? 0 : 'auto'} easing={"easeOutCubic"}>
      <Section>
        <KeyEvent oyKey={key} event={event} onDeleteEvent={onDeleteEvent}></KeyEvent>
      </Section>
    </AnimateHeight>
  );

}

const KeyEvent = (props) => {
  const classes = useStyles();
  const key = props.oyKey;
  const event = props.event;
  
  const handleDeleteEvent = () => {
    if (props.onDeleteEvent)
      props.onDeleteEvent(key, event);
  }

  if (!key) return null;
  return (<div className={classes.keyEvent}>
    <div className={classes.keyEventBody}>
      
      <div className={classes.keyEventTitle}>
      { event.eventType === "COMMENT" ?
          <>
            <span className={classes.keyEventNickName}>{event.user ? event.user.nickName : "-"}</span>
            <span className={classes.keyEventTitleText}>left a comment</span>
          </>
        : undefined }
        { event.eventType === "SCAN" ?
          <>
            <span className={classes.keyEventNickName}>{event.user ? event.user.nickName : "-"}</span>
            <span className={classes.keyEventTitleText}>found the key</span>
          </>
        : undefined }
      </div>
      <div className={classes.keyEventMeta}>
        <div className={classes.eventTimestamp}>{event.eventTimestamp}</div>
        { (event.latitude && event.longitude) ?
          <div className={classes.eventLocation}>{event.latitude} x {event.longitude}</div>
        : undefined }
      </div>
      <div className={classes.keyEvenComment}>
        {event.comment}
      </div>
      { event.imageId ? 
        <div className={classes.keyEventImage}>
          <img src={api.renditionURL(event.imageId, "image", 500, 196)} alt={"Key"}/>
        </div>
      : undefined }
      <EventLocationMiniMap oyKey={key} event={event}/>
    </div>
    <div className={classes.keyEventActions}>
      <div className={classes.keyEventActionsBody}>
        <div className={classes.keyEventAction}>
          <img src={"/trashcan.svg"} alt={"Action: Delete event"} onClick={handleDeleteEvent}></img>
        </div>
      </div>
    </div>
  </div>);
}

const KeyDetails = (props) => {
  const classes = useStyles();
  const { onError, onFlash } = useContext(FlashContext);

  const navigate = useNavigate();
  const params = useParams();
  const keyId = params.keyId || props.keyId;

  const [loading, setLoading] = useState();
  const [key, setKey] = useState();
  useEffect(() => {
    const fetchData = async () => {
      if (keyId) {
        setLoading(true);
        try {
          const key = await api.key(keyId);
          if (key === null)
            onFlash(`Key ${keyId} not found`);
          setKey(key);
        } catch(ex) {
          onError(`Failed to retreive details of key ${keyId}`);
        }
      }
      setLoading(false);
    }
    fetchData();
  }, [keyId]);

  const handlePresetChange = (keyNum) => {
    setKey(null);
    navigate(`/key/${keyNum}`);
  }

  const onSearch = (term) => {
    setKey(null);
    navigate(`/key/${term}`);
  }

  return (<div className={classes.root}>

    <div className={classes.pageTitle}>Key Details</div>

    <div className={classes.filters}>
    <div className={classes.filter}>
        <div className={classes.filterCaption}>Search key# or key id</div>
        <OYSearch defaultValue={keyId} onValidate={onSearch}></OYSearch>
      </div>
      <div className={classes.filter}>
        <div className={classes.filterCaption}>Presets (select key by name)</div>
        <OYSwitch size="XL" captions={[ "Harsh", "LifeInTheBerry" ]} values={[ 23, 1678 ]} onClick={handlePresetChange}></OYSwitch>
      </div>
    </div>

    { key && !loading ? 
      <div className={classes.details}>

        <div className={classes.kpis}>
          <OYAKPIBar>
            <OYKPI value={key.stats.distinctFounds} unit={"users"} caption="Found the key"/>
            <OYKPI value={Math.round(key.stats.kmTravelled*10)/10} unit={"km"} caption="Dist. travelled"/>
            <OYKPI value={key.stats.commentsCount} unit={""} caption="Comments"/>
          </OYAKPIBar>
        </div>

        <div className={classes.qrcode}>
          <QRCode value={`https://app.openyour.art/ui/key/${key.keyId}`} />
        </div>

        <Section key="header">
          <KeyHeader oyKey={key}></KeyHeader>
        </Section>

        <Section key="map">
          <div className={classes.keyMap}>
            <div className={classes.keyMapBody}>
              <KeyLocationMap oyKey={key}></KeyLocationMap>
            </div>
            <div className={classes.keyEventActions}>
            </div>
          </div>
        </Section>

        {
          key.events.map((event, i) => {
            return (<KeyEventSection key={i} oyKey={key} event={event}></KeyEventSection>) 
          })
        }

        <div className={classes.bottomPadding}></div>
      
      </div>
    : undefined }
    { loading ? <OYLoading/> : undefined }

  </div>);
};

export default KeyDetails;


