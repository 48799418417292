
let serverUrl = "";
console.log(`Node envionment: '${process.env.NODE_ENV}'`);
if (process.env.NODE_ENV === 'production') {
  serverUrl = "/api";
}
console.log(`Using server at '${serverUrl}'`);

const config = {
  SERVER_URL: serverUrl
};

export default config;