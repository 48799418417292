import React, { useContext, useEffect, useState } from 'react';
import {createUseStyles} from 'react-jss'
import OYTable from './OYTable';
import OYTableHeader from './OYTableHeader';
import OYTableHeaderCell from './OYTableHeaderCell';
import OYTableRow from './OYTableRow';
import OYTableTextCell from './OYTableTextCell';
import api from './API';
import OYPagination from './OYPagination';
import { useParams } from "react-router-dom";
import OYTableBody from './OYTableBody';
import { FlashContext } from './FlashProvider';

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  pageTitle: {
    marginTop: "14px",
    marginBottom: "4px",
    marginLeft: "8px",
    fontSize: "30px",
    textTransform: "uppercase",
    color: "#00B38F",
  },
  table: {
    margin: "8px",
  },
  action: {
  },
  actionImage: {
    width: "24px",
    height: "24px",
    color: "#00B38F",
    fill: "#00B38F",
    opacity: .5,
    cursor: "pointer",
    "&:hover": {
      opacity: 1
    }  
  }
});


const UserList = (props) => {
  const classes = useStyles();
  const { onError } = useContext(FlashContext);

  const params = useParams();
  const from = +(params.from || 0);
  const max = 20;

  const [users, setUsers] = useState(undefined);
  useEffect(() => {
    api.userList(from, max).then((users) => {
      setUsers(users);
    }).catch((err) => {
      console.error(err);
      onError("Failed to retreive the list of users");
    })
  }, [from, onError]);

  const nextLink = `/users/${from+max}`;
  const prevLink = from >= max ? `/users/${from-max}` : null;

  return (<div className={classes.root}>

    <div className={classes.pageTitle}>User List</div>

    { users ?
      
      <div className={classes.table}>
        <div className={classes.pagination}>
          <OYPagination prevLink={prevLink} nextLink={nextLink}>
          </OYPagination>
        </div>
        <OYTable>
          <OYTableHeader>
            <OYTableHeaderCell width={220} caption="User Id"></OYTableHeaderCell>
            <OYTableHeaderCell width={140} caption="Nick name"></OYTableHeaderCell>
            <OYTableHeaderCell width={80} caption="Events Count"></OYTableHeaderCell>
            <OYTableHeaderCell width={80} caption="Keys Scanned"></OYTableHeaderCell>
            <OYTableHeaderCell width={80} caption="Active days"></OYTableHeaderCell>
            <OYTableHeaderCell width={140} caption="First active"></OYTableHeaderCell>
            <OYTableHeaderCell width={140} caption="Last active"></OYTableHeaderCell>
          </OYTableHeader>
          <OYTableBody>
            { users.map((user, i) => {
              return (
                <OYTableRow key={i}>
                  <OYTableTextCell caption={user.userId}/>
                  <OYTableTextCell caption={user.nickName}/>
                  <OYTableTextCell caption={user.eventCount}/>
                  <OYTableTextCell caption={user.distinctKeysScanned}/>
                  <OYTableTextCell caption={(user.firstEvent && user.lastEvent) ? Math.round((new Date(user.lastEvent) - new Date(user.firstEvent))/3600000/24) : 0}/>
                  <OYTableTextCell caption={user.firstEvent ? user.firstEvent.substring(0, 10): ""}/>
                  <OYTableTextCell caption={user.lastEvent ? user.lastEvent.substring(0, 10): ""}/>
                </OYTableRow>
              );
            })}
          </OYTableBody>
        </OYTable>
      </div>

  : undefined }

  </div>);
};

export default UserList;

