import React from 'react';
import {createUseStyles} from 'react-jss'
import { MapContainer, TileLayer } from 'react-leaflet';
import EventMarker from './EventMarker';

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  map: {
    width: "100%",
    height: "400px"
  },
  popup: {
    display: "flex",
    flexDirection: "column",
  },
  popupTitle: {
    marginBottom: "4px"
  },
  popupTimestamp: {
    color: "#AAA"
  }
});

const EventLocationMiniMap = ({ oyKey, event }) => {
  const classes = useStyles();
  if (!oyKey) return null;
  if (!event || !event.latitude || !event.longitude) return null;
  
  const tiles = 
    <TileLayer
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />

  return (
    <div className={classes.root}>
        <MapContainer className={classes.map} center={[ event.latitude, event.longitude ]} zoom={11} scrollWheelZoom={false}>
          { tiles }
          <EventMarker event={event}></EventMarker>
        </MapContainer>
    </div>);
};

export default EventLocationMiniMap;


