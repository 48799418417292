import React, { useEffect, useState } from 'react';
import {createUseStyles} from 'react-jss'
import OYTable from './OYTable';
import OYTableHeader from './OYTableHeader';
import OYTableHeaderCell from './OYTableHeaderCell';
import OYTableRow from './OYTableRow';
import OYTableTextCell from './OYTableTextCell';
import api from './API';
import OYTableCell from './OYTableCell';
import { Link } from 'react-router-dom';
import OYTableText from './OYTableText';
import OYLoading from './OYLoading';
import OYTableBody from './OYTableBody';

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  pageTitle: {
    marginTop: "14px",
    marginBottom: "4px",
    marginLeft: "8px",
    fontSize: "30px",
    textTransform: "uppercase",
    color: "#00B38F",
  },
  table: {
    margin: "8px",
  },
});

const HallOfFame = (props) => {
  const classes = useStyles();


  const [data, setData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const data = await api.top();
      setData(data);
    }
    fetchData();
  }, [])

  return (<div className={classes.root}>

    <div className={classes.pageTitle}>Top Keys</div>
  
    { !data ? <OYLoading/> : undefined }
    { data ?
    <div className={classes.table}>
      <OYTable>
        <OYTableHeader>
          <OYTableHeaderCell width={60} caption="Key#"></OYTableHeaderCell>
          <OYTableHeaderCell width={90} caption="Users"></OYTableHeaderCell>
          <OYTableHeaderCell width={90} caption="Kilometers"></OYTableHeaderCell>
          <OYTableHeaderCell width={90} caption="Comments"></OYTableHeaderCell>
          <OYTableHeaderCell width={90} caption="Events"></OYTableHeaderCell>
          <OYTableHeaderCell width={90} caption="Images"></OYTableHeaderCell>
          <OYTableHeaderCell width={90} caption="Repors"></OYTableHeaderCell>
          <OYTableHeaderCell width={90} caption="Scans"></OYTableHeaderCell>
        </OYTableHeader>
        <OYTableBody>
          { data.map((row, i) => {
            return (
              <OYTableRow key={i}>
                <OYTableCell>
                  <Link to={`/key/${row.key.keyId}`}>
                    <OYTableText caption={row.key.keyNum}/>
                  </Link>
                </OYTableCell>
                <OYTableTextCell caption={row.stats.distinctFounds}/>
                <OYTableTextCell caption={row.stats.kmTravelled}/>
                <OYTableTextCell caption={row.stats.commentsCount}/>
                <OYTableTextCell caption={row.stats.eventsCount}/>
                <OYTableTextCell caption={row.stats.imagesCount}/>
                <OYTableTextCell caption={row.stats.reportsCount}/>
                <OYTableTextCell caption={row.stats.scansCount}/>
              </OYTableRow>
            );
          })}
        </OYTableBody>
      </OYTable>
    </div>
    : undefined }

  </div>);
};

export default HallOfFame;


