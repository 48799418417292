import React, { useContext, useState } from 'react';
import {createUseStyles} from 'react-jss'
import api from './API';
import { FlashContext } from './FlashProvider';
import { OperatorContext } from './OperatorContext';
import OYButton from './OYButton';
import OYPasswordInput from './OYPasswordInput';
import OYTextInput from './OYTextInput';

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    margin: "auto"
  },
  image: {
    width: "500px",
    maxWidth: "500px",
    height: "100%",
    "& img": {
      width: "500px",
    }
  },
  container: {
    width: "500px",
    height: "270px",
    border: "1px solid #00B38F",
    display: "flex",
    flexDirection: "row",
    margin: "auto",
  },
  dialog: {
    display: "flex",
    flexDirection: "column",
    padding: "22px",
    paddingLeft: 0,
  },
  control: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "26px",
    marginLeft: "40px"
  },
  label: {
  },
  key: {
    marginLeft: "36px",
    marginTop: "64px",
    width: "48px",
    height: "48px",
    "& img": {
      width: "48px",
      height: "48px",  
    }
  }
});

const Logon = (props) => {
  const classes = useStyles();
  const { onError, onFlash } = useContext(FlashContext);
  const { setOperator } = useContext(OperatorContext);

  const [user, setUser] = useState("admin");
  const [password, setPassword] = useState("");
  const onClick = () => {
    onFlash("Logging in...");
    api.setAuthToken();
    setOperator();
    api.login(user, password).then((token) => {
      onFlash();
      api.setAuthToken(token);
      api.operator().then((operator) => {
        setOperator(operator);
      })
    }).catch((err) => {
      console.error(err);
      onError("Login failed");
    })
  }
  const onUserChange = (text) => {
    setUser(text);
  }
  const onPasswordChange = (text) => {
    setPassword(text);
  }

  return (<div className={classes.root}>
    <div className={classes.container}>
      <div className={classes.key}>
        <img src="/logo.png" alt="Key"/>
      </div>
      <div className={classes.dialog}>
        <div className={classes.control}>
          <div className={classes.label}>User name:</div>
          <OYTextInput onChange={onUserChange} defaultValue={user}></OYTextInput>
        </div>
        <div className={classes.control}>
          <div className={classes.label}>Password:</div>
          <OYPasswordInput onValidate={onClick} onChange={onPasswordChange} defaultValue={password}></OYPasswordInput>
        </div>
        <div className={classes.control} style={{ marginLeft: "250px", marginTop: "12px" }}>
          <OYButton caption="Login" onClick={onClick}></OYButton>
        </div>
      </div>
    </div>
  </div>);
};

export default Logon;

