import React, { useEffect, useState } from 'react';
import {createUseStyles} from 'react-jss'
import { useParams } from 'react-router-dom';
import api from './API';
import OYLoading from './OYLoading';

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  pageTitle: {
    marginTop: "14px",
    marginBottom: "4px",
    marginLeft: "8px",
    fontSize: "30px",
    textTransform: "uppercase",
    color: "#00B38F",
  },
  images: {
    marginTop: "24px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "1200px",
    margin: "auto",
  },
  imageAndLegend: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "32px",
    paddingRight: "32px",
    paddingTop: "48px",
    paddingBottom: "48px",
    "background-image": "url('/gallery-back.png')",
  },
  image: {
    margin: "8px",
    height: "90px",
    maxHeight: "90px",
    overflow: "hidden",
    "box-shadow" : "1px 8px 18px #222",
    "& img": {
      width: "240px",
      minWidth: "240px",
      maxWidth: "240px",
    }
  },
  legend: {
    display: "flex",
    flexDirection: "column",
    "box-shadow" : "1px 8px 18px #222",
    width: "80px",
    height: "60px",
    marginLeft: "20px",
    marginTop: "38px",
    marginRight: "26px",
    fontSize: "8px",
    lineHeight: "8px",
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "4px",
    paddingRight: "4px",
    backgroundColor: "#eee",
  },
  legendNickName: {
    color: "#00B38F"
  },
  legendTitleText: {
    color: "#333",
    maxHeight: "16px",
    overflow: "hidden",
    marginBottom: "6px",
  },
  legendMeta: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  gutter: {
    flexGrow: 1,
  },
  legendTimestamp: {
    color: "#AAA",
    textAlign: "right"
  },
  shadowRight: {
    width: "10px",
    marginRight: "120px",
    "background": "linear-gradient(90deg, #816249 0%, rgba(136,136,136,0.5) 39%, rgba(255,255,255,0) 100%)",
  }
});

const Image = ({ event }) => {
  const classes = useStyles();
  return (
    <>
    <div className={classes.imageAndLegend}>
      <div className={classes.image}>
        <img src={`${api.renditionURL(event.imageId, "gallery", 240, 90)}`} alt={event.eventId}/>
      </div>
      <div className={classes.legend}>
        { event.comment ? 
          <div className={classes.legendTitleText}>{event.comment}</div>
        : undefined }
        { event.user ? 
          <div className={classes.legendNickName}>{event.user.nickName}</div>
        : undefined }
        <div className={classes.gutter}/>
        <div className={classes.legendTimestamp}>{event.eventTimestamp.substring(0, 10)}</div>
      </div>
    </div>
    <div className={classes.shadowRight}/>
    </>
  );
}

const Gallery = (props) => {
  const classes = useStyles();
  const params = useParams();

  const from = +(params.from || 0);
  const max = 200;

  const [events, setEvents] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const events = await api.events(from, max, undefined, undefined, true);
      setEvents(events);
    }
    fetchData();
  }, [from, max])

  return (<div className={classes.root}>

      <div className={classes.pageTitle}>Image Gallery</div>
  
    { !events ? <OYLoading/> : undefined }
    <div className={classes.images}>
      { events ? events.map((event, i) => {
        return (<Image key={i} event={event}></Image>);
      })
      : undefined }
    </div>
  
  </div>);
};

export default Gallery;


