import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import {createUseStyles} from 'react-jss'
import { useNavigate } from 'react-router-dom';
import api from './API';
import Logon from './Logon';
import { OperatorContext } from './OperatorContext';

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    margin: "auto"
  }
});

const Logout = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setOperator } = useContext(OperatorContext);

  useEffect(() => {
    setOperator();
    api.logout().then(() => {
      navigate(`/keys`);
    }).catch((ex) => {
      navigate(`/keys`);
    })
  })

  return (<div className={classes.root}>
    <Logon></Logon>
  </div>);
};

export default Logout;
