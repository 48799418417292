/*
 * (C) Copyright 2021 Open Your [...] (http://openyour.art/) and others.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Contributors:
 *     Alexandre Morin
 */
import React from 'react';
import {createUseStyles} from 'react-jss'
import { Link } from 'react-router-dom';

/**
 * Pagination allows to navigate across mutliple pages on list tables. 
 * It renders "Next" and "Previous" arrows. Each link can be disabled if there is no corresponding url.
 */
const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "24px",
    width: "100%",
  },
  left: {
    marginRight: "20px",
  },
  right: {
    marginLeft: "20px",
  },
  disabled: {
    opacity: 0.4
  },
  link: {
    display: "flex",
    flexDirection: "row",
  },
  label: {
    marginLeft: "8px",
    color: "#333",
    fontSize: "12px"
  }
});

const OYPagination = (props) => {
  const classes = useStyles();
  const { prevLink, nextLink } = props;
  return (
    <div className={classes.root}>
      <div className={`${classes.left} ${prevLink ? "" : classes.disabled}`}>
        <Link className={classes.link} to={prevLink || "#"}>
          <img src="/left.svg" alt="Left"/>
          <div className={classes.label}>Previous</div>
        </Link>
      </div>
      { props.children }
      <div className={`${classes.right} ${nextLink ? "" : classes.disabled}`}>
        <Link className={classes.link} to={nextLink || "#"}>
          <img src="/right.svg" alt="Right"/>
          <div className={classes.label}>Next</div>
        </Link>
      </div>
    </div>
  );
};

export default OYPagination;


