
import './App.css';
import OYButton from './OYButton';
import OYAppBar from './OYAppBar';
import OYButtonBar from './OYButtonBar';
import OYButtonGroup from './OYButtonGroup';
import {createUseStyles} from 'react-jss'
import EventLog from './EventLog';
import KeyList from './KeyList';
import KeyDetails from './KeyDetails';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Link } from "react-router-dom";
import HallOfFame from './HallOfFame';
import Gallery from './Gallery';
import KeyMap from './KeyMap';
import React, { useState } from 'react';
import OYFlashMessage from './OYFlashMessage';
import UseLogon from './UseLogon';
import FlashProvider from './FlashProvider';
import api from './API';
import { OperatorContext } from './OperatorContext';
import Logon from './Logon';
import Logout from './Logout';
import Profile from './Profile';
import OperatorList from './OperatorList';
import UserList from './UserList';
import { useEffect } from 'react';
import Zoo from './Zoo';
import Thumbs from './Thumbs';
import Push from './Push';

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    minHeight: "100%",
    flexGrow: "1",
  },
  shell: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    flexGrow: "1",
  },
  inner: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    minHeight: "100%",
    flexGrow: "1",
  },
  menu: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "20px",
    borderRight: "1px solid #ccc",
    paddingLeft: "8px",
    paddingRight: "8px",
    height: "100%",
    flexGrow: "1",
  },

});

let messageId = 0;

function App() {
  const classes = useStyles();

  const [flashMessage, setFlashMessage] = useState();

  const setFlashMessageWithTimeout = (flash) => {
    if (flash) {
      messageId = messageId + 1;
      flash.messageId = messageId;
      setTimeout(() => {
        setFlashMessage()
      }, 2000)
    }
    setFlashMessage(flash);
  }

  const onError = (err) => {
    console.log("onError", err);
    setFlashMessageWithTimeout({ level: "error", message: err });
  }

  const onFlash = (flash) => {
    if (!flash) return setFlashMessageWithTimeout();
    if (typeof flash === "string") {
      return setFlashMessageWithTimeout({ level: "info", message: flash });
    }
  }

  const [version, setVersion] = useState();
  const [operator, setOperator] = useState();
  useEffect(() => {
    api.version().then((version) => {
      setVersion(version.version);
      api.operator().then((operator) => {
        setOperator(operator);
      })
    });
  }, []);

  return (
    <BrowserRouter>
      <FlashProvider onError={onError} onFlash={onFlash}>
        { flashMessage ? 
            <OYFlashMessage level={flashMessage.level} message={flashMessage.message}></OYFlashMessage>
        : undefined }

        <OperatorContext.Provider value={{operator, setOperator}}>
          <div className={classes.root}>
            <OYAppBar logo="/logo.png" caption="OPEN YOUR […]" version={version} operatorName={operator ? operator.name : ""} operatorAvatar={operator ? api.renditionURL(operator.avatar, "thumb", 30, 30) : ""} ></OYAppBar>

            <UseLogon>
              <div className={classes.shell}>

                <div className={classes.menu}>
                  <OYButtonBar layout="vertical">
                    <OYButtonGroup layout="vertical">
                      <Link to="/keys"><OYButton caption="Key List"></OYButton></Link>
                      <Link to="/users"><OYButton caption="User List"></OYButton></Link>
                      <Link to="/key"><OYButton caption="Key"></OYButton></Link>
                      <Link to="/gallery"><OYButton caption="Gallery"></OYButton></Link>
                      <Link to="/keyMap"><OYButton caption="Key Map"></OYButton></Link>
                    </OYButtonGroup>
                    <OYButtonGroup layout="vertical">
                      <Link to="/events"><OYButton caption="Event Log"></OYButton></Link>
                      <Link to="/top"><OYButton image="/hall-of-fame.png"></OYButton></Link>
                      <Link to="/push"><OYButton caption="Push"></OYButton></Link>
                      <Link to="/thumbs"><OYButton caption="Thumbnails"></OYButton></Link>
                    </OYButtonGroup>
                    <OYButtonGroup layout="vertical">
                      <Link to={ (operator && operator.name !== "nemo") ? "/profile": "#" }><OYButton caption="Profile" disabled={!operator || operator.name === "nemo"}></OYButton></Link>
                      <Link to="/operators"><OYButton caption="Operators"></OYButton></Link>
                      <Link to="/login"><OYButton caption="Login"></OYButton></Link>
                      <Link to="/logout"><OYButton caption="Logout"></OYButton></Link>
                    </OYButtonGroup>
                  </OYButtonBar>
                </div>

                <div className={classes.inner}>
                  <Routes>
                    <Route path="/" element={ <KeyList></KeyList> } />
                    <Route path="/login" element={ <Logon></Logon> } />
                    <Route path="/logout" element={ <Logout></Logout> } />
                    <Route path="/profile" element={ <Profile></Profile> } />
                    <Route path="/operators" element={ <OperatorList></OperatorList> } />
                    <Route path="/keys" element={ <KeyList></KeyList> } >
                      <Route path=":from" element={ <KeyList></KeyList> } />
                    </Route>
                    <Route path="/users" element={ <UserList></UserList> } >
                      <Route path=":from" element={ <UserList></UserList> } />
                    </Route>
                    <Route path="/key" element={ <KeyDetails></KeyDetails> } >
                      <Route path=":keyId" element={ <KeyDetails></KeyDetails> } />
                    </Route>
                    <Route path="/events" element={ <EventLog></EventLog> }>
                      <Route path=":from" element={ <EventLog></EventLog> } />
                    </Route>
                    <Route path="/top" element={ <HallOfFame></HallOfFame> } />
                    <Route path="/gallery" element={ <Gallery></Gallery> } />
                    <Route path="/keyMap" element={ <KeyMap></KeyMap> } />
                    <Route path="/push" element={ <Push></Push> } />
                    <Route path="/thumbs" element={ <Thumbs></Thumbs> } />

                    <Route path="/zoo" element={ <Zoo></Zoo> } />
                  </Routes>
                </div>

              </div>
            </UseLogon>

          </div>
        </OperatorContext.Provider>        
      </FlashProvider>
    </BrowserRouter>
  );
}

export default App;

