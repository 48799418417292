/*
 * (C) Copyright 2021 Open Your [...] (http://openyour.art/) and others.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Contributors:
 *     Alexandre Morin
 */
import React from 'react';
import {createUseStyles} from 'react-jss'

/**
 * A KPI components renders the value of an indicator. 
 * The most simple KPI only contains a `value`, but it's also common to add a `unit`. 
 * For example, if you want to display a distance, the value may be `1200` and the unit `"km"`.
 *
 * In addition, KPIs can have a `caption` which gives more details of what the KPI is.
 * For instance, in the previous example, the caption could be `"Distance travelled"`.
 */
const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFF",
    width: "180px",
    height: "76px",
    marginRight: "8px",
    paddingLeft: "6px",
    paddingRight: "6px",
    paddingTop: "10px",
    border: "1px solid #CCC",
    fontSize: "20px",
    boxSizing: "border-box",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  value: {
    fontSize: "28px",
  },
  unit: {
    fontSize: "16px",
    color: "#AAA",
    marginLeft: "10px",
  },
  caption: {
    fontSize: "16px",
    color: "#AAA",
    marginTop: "4px",
  },
  normal: {
    color: "#00B38F",
    borderLeft: "4px solid #00B38F",
  },
  error: {
    color: "rgb(179, 0, 143)",
    borderLeft: "4px solid rgb(179, 0, 143)",
  },
});

const OYKPI = ({ value, unit, caption, flavor }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${classes[flavor || "normal"]}`}>
      <div className={classes.row}>
        <div className={classes.value}>{ value }</div>
        <div className={classes.unit}>{ unit }</div>
      </div>
      <div className={classes.row}>
        <div className={classes.caption}>{ caption }</div>
      </div>
    </div>);
};

export default OYKPI;


