import React from 'react';
import {createUseStyles} from 'react-jss'

const useStyles = createUseStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    "z-index": 10000
  },
  flash: {
    paddingTop: "5px",
    paddingBottom: "6px",
    paddingLeft: "24px",
    paddingRight: "24px",
    width: "800px",
    margin: "auto"
  },
  error: {
    backgroundColor: "rgb(179, 0, 143)",
    color: "#FFFFFF",
  },
  info: {
    backgroundColor: "#00B38F",
    color: "#FFFFFF",
  },
  message: {
  },
  inline: {
  },
  top: {
    position: "absolute",
    top: 120,
  }
});

const OYFlashMessage = ({ level, message, position }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${classes[position || "top"]}`}>
      <div className={`${classes.flash} ${classes[level || "info"]}`}>
        <div className={classes.message}>{message}</div>
      </div>
    </div>
  );
};

export default OYFlashMessage;


