import React, { useContext } from 'react';
import {createUseStyles} from 'react-jss'
import api from './API';
import Logon from './Logon';
import { OperatorContext } from './OperatorContext';

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  gutter: {
    display: "flex",
    flexDirection: "column",
    flewGrow: 1
  }
});

const UseLogon = (props) => {
  const classes = useStyles();
  const { operator } = useContext(OperatorContext);
  const children = props.children.length > 0 ? props.children : [props.children];
  const token = api.getAuthToken();
  
  return (<div className={classes.root}>
    { !operator || !token ? 
      <>
        <div className={classes.gutter}></div>
        <Logon></Logon> 
        <div className={classes.gutter}></div>
      </>
    : children }
  </div>);
};

export default UseLogon;

