import React, { useContext, useState } from 'react';
import {createUseStyles} from 'react-jss'
import api from './API';
import { FlashContext } from './FlashProvider';
import OYButton from './OYButton';
import OYProgressBar from './OYProgressBar';
import OYTable from './OYTable';
import OYTableBody from './OYTableBody';
import OYTableCell from './OYTableCell';
import OYTableHeader from './OYTableHeader';
import OYTableHeaderCell from './OYTableHeaderCell';
import OYTableRow from './OYTableRow';
import OYTableTextCell from './OYTableTextCell';
import useInterval from './useInterval';

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  pageTitle: {
    marginTop: "14px",
    marginBottom: "4px",
    marginLeft: "8px",
    fontSize: "30px",
    textTransform: "uppercase",
    color: "#00B38F",
  },
  tableTitle: {
    marginTop: "14px",
    marginBottom: "4px",
    marginLeft: "8px",
    fontSize: "12px",
    //textTransform: "uppercase",
    //color: "#00B38F",
  },
  table: {
    margin: "8px",
  },
  filters: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FFF",
    borderTop: "1px solid #eee",
    borderBottom: "1px solid #ccc",
    marginTop: "8px",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "18px",
    paddingRight: "8px",
  },
  filter: {
    marginRight: "16px",
  },
  filterCaption: {
    fontSize: "10px"
  },
  job: {
    marginLeft: "8px",
    marginTop: "16px",
  },
  jobCaption: {
    fontSize: "12px"
  },
  timestamp: {
    "font-size": "10px",
    "line-height": "14px",
  },
  progress: {
    marginLeft: "8px",
  }
});

const Thumbs = (props) => {
  const classes = useStyles();
  const { onError } = useContext(FlashContext);
  const [ jobs, setJobs ] = useState();

  const poll = () => {
    api.getJobs(10, "THUMBS").then((jobs) => {
      setJobs(jobs);
    }).catch((err) => {
      console.error(err);
      onError("Failed to poll jobs");
    })
  }

  useInterval(poll, 1000);

  const onClick = () => {
    api.generateMissing(2000).then((job) => {
      console.log(job);
    }).catch((err) => {
      console.error(err);
      onError("Failed to generate missing thumbnails");
    });
  }

  return (<div className={classes.root}>

    <div className={classes.pageTitle}>Thumbnails</div>

    <div className={classes.filters}>
      <div className={classes.filter}>
        <div className={classes.filterCaption}>Generate missing thumbnails</div>
        <OYButton caption="Generate" onClick={onClick}></OYButton>
      </div>
    </div>


    { jobs ?
      
      <>
        <div className={classes.tableTitle}>Last 10 thumbnail generation jobs</div>
        <div className={classes.table}>
          <OYTable>
            <OYTableHeader>
              <OYTableHeaderCell width={300} caption="Name"></OYTableHeaderCell>
              <OYTableHeaderCell width={80} caption="Start (UTC)"></OYTableHeaderCell>
              <OYTableHeaderCell width={80} caption="End (UTC)"></OYTableHeaderCell>
              <OYTableHeaderCell width={80} caption="Duration"></OYTableHeaderCell>
              <OYTableHeaderCell width={140} caption="Progress"></OYTableHeaderCell>
              <OYTableHeaderCell width={220} caption="Status"></OYTableHeaderCell>
            </OYTableHeader>
            <OYTableBody>
              { jobs.map((job, i) => {

                  let status;
                  if (job.status === "pending") status = "Queued";
                  if (job.status === "running") status = `In progress ${job.progress} / ${job.max}...`;
                  if (job.status === "finished" && job.exitCode === 0) status = "Finished successfully";
                  if (job.status === "finished" && job.exitCode !== 0) status = `Failed with error ${job.exitCode} (${job.failure})`;
                  if (job.status === "aborted") status = "Aborted";

                  let duration;
                  const endedAt = job.status === "running" ? job.updatedAt : job.endedAt;
                  if (job.startedAt && endedAt) {
                    const start = new Date(job.startedAt);
                    const end = new Date(endedAt);
                    const deltaSeconds = (end - start)/1000;

                    if (deltaSeconds < 60) {
                      const seconds = Math.round(deltaSeconds);
                      if (seconds)
                        duration = seconds + " seconds";
                    }
                    else {
                      duration = Math.round(deltaSeconds / 60) + " minutes";
                    }
                  }

                  return (
                  <OYTableRow key={i}>
                    <OYTableTextCell caption={job.name}/>
                    <OYTableCell>
                      { job.startedAt ? 
                        <>
                          <div className={classes.timestamp}>{job.startedAt.substring(0, 10)}</div>
                          <div className={classes.timestamp}>{job.startedAt.substring(11, 19)}</div>
                        </>
                      : undefined }
                    </OYTableCell>
                    <OYTableCell>
                    { job.endedAt ? 
                        <>
                          <div className={classes.timestamp}>{job.endedAt.substring(0, 10)}</div>
                          <div className={classes.timestamp}>{job.endedAt.substring(11, 19)}</div>
                        </>
                      : undefined }
                    </OYTableCell>
                    <OYTableTextCell caption={duration}/>
                    <OYTableCell>
                      <div className={classes.progress}>
                        <OYProgressBar size="S" progress={ job.max === 0 ? 1 : job.progress / job.max}></OYProgressBar>
                      </div>
                    </OYTableCell>
                    <OYTableTextCell caption={status}/>
                    
                  </OYTableRow>
                );
              })}
            </OYTableBody>
          </OYTable>
        </div>
      </>
  : undefined }

  </div>);
};

export default Thumbs;
